import React from 'react'
import PropTypes from 'prop-types'
import { NavItemLi } from './style'

const NavItemAnchor = ({ href, children }) => (
  <NavItemLi>
    <a href={ href }>{children}</a>
  </NavItemLi>
)

NavItemAnchor.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
}

export default NavItemAnchor
