import { rgba } from 'polished'

const lightGreen = '#00e5a6'
const green = '#6ccdb9'
const teal = '#e0fbf4'
const blueLagoon = '#104784'
const seaGreen = 'rgb(35,188,186)'
const darkGreen = '#00978e'
const darkBlue = '#163262'
const midnightBlue = '#032148'
const blueSky = '#89ebe5'
const cornflowerBlue = '#94C0E4'
const hanBlue = '#5672C7'
const purple = '#726AFF'
const deepBlue = '#11274d'
const blueChart = '#029BE7'
const yellow = '#ffed61'
const deepGold = '#ffcc00'
const red = '#e84c3c'
const deepPurple = '#a752bb'

// Badges
const bronze = '#78481f'
const silver = '#c6cfcf'
const gold = '#f2e261'
const platinum = '#086F6B'
const diamond = '#d1fffa'
const titanium = '#505050'
const quartz = '#fcddff'
const adamantium = '#80806a'
const sapphire = '#2158a6'
const ruby = '#c52121'
const emerald = '#2da955'
const obsidian = '#71228f'
const mithril = '#70bbba'

const brown = '#e6a970'
const lightGray = '#e9f0f7'
const mediumGray = '#bdc0c7'
const gray = '#888'
const blueGray = '#94a4b0'
const darkGray = '#728898'
const pink = '#f94a6b'
const white = '#fff'
const black = '#000'
const transparent = 'transparent'

const colors = {
  lightGreen,
  teal,
  green,
  blueLagoon,
  seaGreen,
  darkGreen,
  darkBlue,
  midnightBlue,
  blueSky,
  cornflowerBlue,
  hanBlue,
  purple,
  deepPurple,
  deepBlue,
  blueChart,
  yellow,
  gold,
  deepGold,
  red,
  silver,
  bronze,
  diamond,
  platinum,
  titanium,
  quartz,
  adamantium,
  sapphire,
  ruby,
  emerald,
  obsidian,
  mithril,
  brown,
  lightGray,
  mediumGray,
  gray,
  blueGray,
  darkGray,
  pink,
  white,
  black,
  transparent,
  formInputBackground: rgba(darkGreen, 0.5),
}

export default colors
