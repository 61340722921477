import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import LicencesThreshold from '../Subscriptions/LicencesThreshold'
import DetailsItem from './DetailsItem'
import { colors } from 'theme'
import { getSubscriptionDetails } from '../../../utils/origin'
import { MONTH_INTERVAL, TEAM_MENSUAL } from 'constants/subscription'
import { isPharmUppUltra } from 'utils/origin'

const resolveDetailsItem = (title = '', interval = '') => {
  if (title === TEAM_MENSUAL && interval === MONTH_INTERVAL) {
    return getSubscriptionDetails(true)
  }

  return getSubscriptionDetails()
}

const DrawerTop = ({
  plan,
}) => {
  const {
    title,
    description,
    interval,
    metadata: {
      threshold,
    },
  } = plan

  return (
    <Wrapper>
      <TopWrapper>
        <TopLeftWrapper>
          <FirstPartTitle>
            {'L’abonnement'}
          </FirstPartTitle>
          <Title>
            {title}
          </Title>
          <LicencesThreshold threshold={ threshold } />
          <Quote>
            {description}
          </Quote>
        </TopLeftWrapper>
      </TopWrapper>
      <Details>
        {
          !isPharmUppUltra()
          ?
            <>
              <DetailsItem>{ resolveDetailsItem(title, interval).trialPeriod }</DetailsItem>
              <DetailsItem>{`Abonnement ${interval === 'month' ? 'mensuel' : 'annuel'}`}</DetailsItem>
              <DetailsItem>Renouvellement automatique pouvant être annulé à tout moment</DetailsItem>
            </>
          : null
        }
      </Details>
    </Wrapper>
  )
}

DrawerTop.propTypes = {
  plan: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    interval: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      threshold: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.78rem 2.25rem;
  color: ${colors.white};
  background-image: linear-gradient(119deg, ${colors.blueLagoon}, ${colors.midnightBlue});

  @media (width <= 550px) {
    display: block;
    padding-bottom: 1rem;
  }
`

const TopWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  margin-bottom: 1rem;
`
const TopLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const FirstPartTitle = styled.span`
  margin-top: 1.75rem;
  font-family: 'Brother 1816';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
`

const Title = styled.h1`
  margin: 0 0 1.5rem;
  font-family: 'Brother 1816';
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 900;
`

const Quote = styled.p`
  margin-top: 1.65rem;
  font-family: 'Brother 1816';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5rem;
`

const Details = styled.ul`
  padding: 0;
  margin: 0;
`

export default DrawerTop
