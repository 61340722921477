import React from 'react'
import NavItem from './NavItem'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Logo, NavWrapper } from './style'

const Nav = ({ navItems, children, ...props }) => {
  const navigate = useNavigate()

  return (
    <NavWrapper { ...props }>
      <Icon
        onClick={ () => navigate('/') }
      >
        <Logo />
      </Icon>
      <ul>
        { navItems.map(navItem =>
          <NavItem
            active={ navItem.active }
            key={ navItem.to }
            to={ navItem.to }
          >
            {navItem.content}
          </NavItem>,
        )}
      </ul>
      { children }
    </NavWrapper>
  )
}

const Icon = styled.div`
  cursor: pointer;
`

Nav.propTypes = {
  children: PropTypes.node,
  navItems: PropTypes.arrayOf(PropTypes.shape({
    to: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
  })).isRequired,
}

Nav.defaultProps = {
  children: null,
}

export default Nav
