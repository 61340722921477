import { gql } from '@apollo/client'

export const ALL_JOBS = gql`
  query allJobs {
    allJobs {
      key
      title
    }
  }
`
