import React from 'react'
import DrawerTop from './DrawerTop'
import DrawerBottom from './DrawerBottom'

const DrawerContent = props =>
  <>
    <DrawerTop { ...props } />
    <DrawerBottom { ...props } />
  </>

export default DrawerContent
