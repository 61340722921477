import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from 'theme'
import RecapItem from './RecapItem'
import { Button } from 'components/atoms'

const SubscriptionCard = ({
  plan,
  onClick,
}) =>
  <Wrapper>
    <Header>
      <HeaderLeft>
        <Title>
          {'Abonnement ' + plan.title}
        </Title>
        <PriceWrapper>
          <PriceInfos>
            { `${plan.amount / 100}€ ${plan.metadata.taxType} / ${plan.interval === 'year' ? 'an' : 'mois' }` }
          </PriceInfos>
          <PriceInfos>
            {plan.metadata.threshold > 1 ?
              ' par licence'
              : ''
            }
          </PriceInfos>
        </PriceWrapper>
        <Recap>
          <RecapItem>
            {plan.interval === 'year' ? 'Abonnement annuel' : 'Abonnement mensuel' }
          </RecapItem>
          <RecapItem>
            {plan.metadata.threshold > 1
              ? 'À partir de 2 licences, dont la vôtre'
              : '1 licence'
            }
          </RecapItem>
        </Recap>
      </HeaderLeft>
      <HeaderRight>
        <ChooseLicence
          size="medium"
          variant="primary"
          onClick={ onClick }
        >
          Choisir
        </ChooseLicence>
      </HeaderRight>
    </Header>
  </Wrapper>

SubscriptionCard.propTypes = {
  plan: PropTypes.shape({
    title: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    interval: PropTypes.string.isRequired,
    metadata: {
      taxType: PropTypes.string.isRequired,
      displayPrice: PropTypes.string.isRequired,
      threshold: PropTypes.number.isRequired,
    },
  }).isRequired,
  onClick: PropTypes.func.isRequired,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  width: 40.5rem;
  padding: 1.125rem;
  padding-bottom: 1.3125rem;
  margin-top: 1rem;
  margin-left: -2.5rem;
  background: linear-gradient(114.07deg, #104784 0%, #06264f 100%);
  border-radius: 8px;
`

const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
`

const HeaderRight = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.span`
  margin-bottom: 1.3125rem;
  font-family: 'Brother 1816';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 900;
  color: ${colors.white};
  user-select: none;
`

const PriceWrapper = styled.span`
  font-family: Montserrat;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: normal;
  color: ${colors.white};
`

const PriceInfos = styled.span`
  font-family: Montserrat;
  font-weight: 700;
`

const Recap = styled.div `
  padding: 1rem 0 0;
`

const ChooseLicence = styled(Button)`
  && {
    position: relative;
    justify-content: center;
    width: 1px;
    margin: 0.4rem;
    color: ${colors.lightGreen};
    background-image: none;
    border: 1px solid ${colors.lightGreen};
    box-shadow: none;
  }
`

export default SubscriptionCard
