import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'
import {
  FoldableFAQ,
} from './index'

const FoldableFAQList = () => {
  const [openedIndex, setOpenedIndex] = useState(null)

  const onToggle = (index) => () => {
    if (index === openedIndex) {
      setOpenedIndex(null)

      return
    }

    setOpenedIndex(index)
  }

  return (
    <Wrapper>
      { FAQs.map((faq, index) =>
        <FoldableFAQ
          key={ faq.question }
          opened={ openedIndex === index }
          onToggle={ onToggle(index) }
          { ...faq }
        />,
      ) }
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-bottom: 3rem;
`

const FAQAnswerList = styled.ul`
  padding-left: 1.5rem;
`

const List = styled.li`
  margin: 1rem 0;
`

const Bold = styled.em`
  font-weight: bold;
  color: ${colors.darkBlue};
`

const FAQs = [
  {
    question: 'Dois-je m\'inscrire spécifiquement pour valider mon DPC avec Défimédoc ?',
    answer: (
      <FAQAnswerList>
        <List>
          Non, Si vous remplissez toutes les conditions,
          <Bold>
            {' '}
            vous recevrez automatiquement votre attestation de DPC
            {' '}
          </Bold>
          un an après votre date d’abonnement à Défimédoc.
        </List>
        <List>
          <Bold>Si vous êtes déjà abonné au 1er avril 2024</Bold>
          , vous recevrez votre attestation de DPC par mail début avril 2025.
        </List>
        <List>
          <Bold>Si vous vous abonnez après le 1er avril 2024</Bold>
          , vous recevrez votre attestation de DPC par mail un an après le début de votre abonnement.
        </List>
      </FAQAnswerList>
    ),
  },
  {
    question: 'Puis-je suivre ma formation sur ordinateur ?',
    answer: (
      <FAQAnswerList>
        <List>
          Les Quiz et Fiches l&apos;Essentiel
          <Bold>
            {' '}
            ne sont disponibles que sur l&apos;application Défimédoc
          </Bold>
          .
        </List>
        <List>
          Le téléchargement de l&apos;application est gratuit sur AppStore ou Playstore. Une fois abonné, vous aurez automatiquement accès aux Quiz, à raison de 2 nouveaux Quiz par semaine.
        </List>
        <List>
          Les Quiz peuvent être faits sur
          <Bold>
            {' '}
            smartphone ou tablette
          </Bold>
          .
        </List>
      </FAQAnswerList>
    ),
  },
  {
    question: 'Puis-je interrompre ma formation pendant mes congés ?',
    answer: (
      <FAQAnswerList>
        <List>
          Tous les Quiz
          <Bold> restent disponibles </Bold>
          dans la colonne &ldquo;A faire&rdquo; de l&apos;application, s&apos;ils ne sont pas faits immédiatement.
        </List>
        <List>
          Vous pouvez donc interrompre vos Quiz pendant vos congés, sous réserve de valider 60% des Quiz minimum pendant l&apos;année.
        </List>
      </FAQAnswerList>
    ),
  },
  {
    question: 'Puis-je refaire plusieurs fois chaque Quiz ?',
    answer: (
      <FAQAnswerList>
        <List>
          Tous les Quiz peuvent être
          <Bold>
            {' '}
            refaits autant de fois que désiré
          </Bold>
          .
        </List>
        <List>
          Ils sont validés pour un score ≥ 70/100.
        </List>
        <List>
          Même une fois validés, ils peuvent être refaits à volonté.
        </List>
      </FAQAnswerList>
    ),
  },
]

export default FoldableFAQList
