import React, { FC, ReactElement } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'
import { Button, Icon } from '../atoms'

type Props = {
  question: ReactElement;
  answer: string;
  opened: boolean;
  onToggle: () => void;
}

const FoldableFAQ: FC<Props> = ({
  question,
  answer,
  opened,
  onToggle,
}) => (
  <Wrapper>
    <header>
      <Title className="text-subheading">
        {question}
      </Title>
      <Button
        className={ opened ? 'opened' : '' }
        variant='primary'
        onClick={ onToggle }
      >
        <Icon
          className={ opened ? 'close' : 'plus' }
          name={ opened ? 'close' : 'plus' }
        />
      </Button>
    </header>
    { opened &&
      <Answer className="text-content">
        {answer}
      </Answer>
    }
  </Wrapper>
)

const Title = styled.h4`
  margin-bottom: 1rem;
  font-size: 1.5em;
  line-height: 36px;
`

const Answer = styled.div`
  margin: 1rem 0 3rem;
`

const Wrapper = styled.article`
  margin-bottom: 1rem;
  border-bottom: 2px solid ${colors.purple};

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    margin-left: 5rem;
    text-align: center;
    border-radius: 50px;
  }

  button.opened {
    background: linear-gradient(114.07deg, ${colors.blueLagoon} 11.29%, ${colors.deepBlue} 124.32%);
  }

  .close {
    fill: ${colors.white};
  }

  .plus {
    filter: invert();
  }

  @media (width <= 600px) {
    button {
      margin-left: 1rem;
    }
  }
`

export default FoldableFAQ
