import { logoShort } from 'assets/svgs/index'
import styled from 'styled-components'
import { colors } from 'theme'

export const NavWrapper = styled.nav`
  position: absolute;
  z-index: 10;
  box-sizing: border-box;
  display: grid;
  grid-template: 'logo children items' auto / 4rem 1fr auto;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;

  :nth-child(2) {
    grid-area: children;
  }

  > ul {
    display: flex;
    grid-area: items;
    justify-content: flex-start;
    padding: 0;
    margin: 0 0 0 5rem;
    overflow-y: auto;

    li {
      white-space: nowrap;
    }

    @media (width <= 1050px) {
      margin: 0;
    }
  }

  @media (width <= 1200px) {
    grid-template:
      'logo items' auto
      'children children' 5rem / 1fr auto;
  }
`

export const NavItemLi = styled.li`
  align-self: center;
  margin-right: 5%;
  list-style-type: none;

  a {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    color: ${colors.white};
  }

  &.inactive {
    opacity: 0.7;
  }

  &.active {
    position: relative;

    a {
      font-weight: bold;
    }

    &::after {
      position: absolute;
      bottom: -0.5rem;
      left: 10%;
      width: 80%;
      content: "";
      border-bottom: 3px solid ${colors.lightGreen};
      border-radius: 10px;
    }
  }

  &:last-of-type {
    margin-right: unset;
  }

  @media (width <= 600px) {
    padding: 0.8rem 0;
  }
`

export const Logo = styled(logoShort)`
  grid-area: logo;
  width: 4rem;
  fill: ${colors.lightGreen};
`

