import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Card } from 'components/atoms'
import DiscountBanner from '../DiscountBanner'
import LicencesThreshold from './LicencesThreshold'
import { colors } from 'theme'
import { isPharmUppUltra } from 'utils/origin'

const SubscriptionCard = ({
  onClick,
  title,
  description,
  interval,
  metadata: {
    threshold,
    taxType,
    displayPrice,
    discount,
  },
}) => {
  const HeaderMiddle = (
    <>
      { isPharmUppUltra() ? 'Spécial Pharm\'UPP ULTRA' : `${title}` }
      <PriceWrapper>
        <Price>
          {`${displayPrice}€`}
        </Price>
        <PriceInfos>
          <PriceTaxType>
            {`${taxType} / ${interval === 'month' ? 'mois' : 'an'}`}
          </PriceTaxType>
          { threshold > 1
            ?
              <PricePerLicence>
                par licence
              </PricePerLicence>
            : null }
        </PriceInfos>
      </PriceWrapper>
    </>
  )

  const hasDiscountBanner = discount !== null
  const hasDescription = description !== null

  const Content = (
    <>
      { hasDiscountBanner &&
        <DiscountBanner
          description={ discount.description }
          title={ discount.title }
        />
      }
      {hasDescription
        ?
          <Quote>
            {`« ${description} »`}
          </Quote>
        : null
      }
    </>
  )

  return (
    <Card
      buttonLabel="Voir en détail"
      buttonOnClick={ onClick }
      content={ Content }
      headerBottom={ <LicencesThreshold threshold={ threshold } /> }
      headerMiddle={ HeaderMiddle }
      headerTop="L'abonnement"
    />
  )
}

SubscriptionCard.propTypes = {
  description: PropTypes.string.isRequired,
  interval: PropTypes.string.isRequired,
  metadata: PropTypes.shape({
    taxType: PropTypes.string.isRequired,
    threshold: PropTypes.number.isRequired,
    displayPrice: PropTypes.string.isRequired,
    discount: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  }).isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

SubscriptionCard.defaultProps = {
  discount: null,
}

const PriceWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-bottom: 1.6875rem;
`

const Price = styled.span`
  margin-right: 1rem;
  font-family: 'Brother 1816';
  font-size: 2.25rem;
  font-style: normal;
  font-weight: bold;
  color: ${colors.white};
`

const PriceInfos = styled.div`
  display: flex;
  flex-direction: column;
`

const PriceTaxType = styled.span`
  font-family: Montserrat;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: normal;
  color: ${colors.white};
`

const PricePerLicence = styled.span`
  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: normal;
  color: ${colors.white};
`

const Quote = styled.blockquote`
  font-family: 'Brother 1816';
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.375rem;
  color: ${colors.midnightBlue};
  text-align: center;
`

export default SubscriptionCard
