import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'components/atoms'
import { colors } from 'theme'

const StoreButton = ({
  availability,
  icon,
  url,
}) =>
  <Wrapper>
    <Availability>
      {`Disponible sur ${availability}`}
    </Availability>
    <StyledButton
      color={ colors.purple }
      href={ url }
      icon={ icon }
      iconSize="1.5em"
      title="Télécharger"
      variant="primary"
    />
  </Wrapper>

StoreButton.propTypes = {
  availability: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
`

const Availability = styled.span`
  margin-bottom: 1.25rem;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  color: ${colors.lightGray};
  opacity: 0.6;
`

const StyledButton = styled(Button)`
  && {
    padding: 1.2rem 0.8rem;
    background-color: ${colors.white};
    background-image: initial;
  }
`

export default StoreButton
