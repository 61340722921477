import React from 'react'
import styled, { keyframes } from 'styled-components'
import { colors } from 'theme'

const Spinner = () => (
  <Wrapper
    role='status'
  >
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </Wrapper>
)

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    box-sizing: border-box;
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid ${colors.lightGreen};
    border-color: ${colors.lightGreen} transparent transparent transparent;
    border-radius: 50%;
    animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`

export default Spinner
