import { gql } from '@apollo/client'

export const ADD_TEAM_MEMBER = gql`
  mutation AddTeamMember(
    $email: String!
    $firstname: String!
    $lastname: String!
  ) {
    addTeamMember(
      email: $email
      firstname: $firstname
      lastname: $lastname
    ) {
      id
    }
  }
`

export const DELETE_TEAM_MEMBER = gql`
    mutation DeleteTeamMember($email: String!) {
        deleteTeamMember(email: $email) {
            id
        }
    }
`

export const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription {
    cancelSubscription {
      id
    }
  }
`

export const UPDATE_PAYMENT_METHOD = gql`
  mutation UpdatePaymentMethod($paymentMethodId: String!) {
    updatePaymentMethod(paymentMethodId: $paymentMethodId) {
      threeDSRedirectUrl
    }
  }
`

export const UPDATE_MY_PHARMACY = gql`
  mutation UpdateMyPharmacy(
    $pharmacy: PharmacyInput!
  ) {
    updateMyPharmacy(
      pharmacy: $pharmacy
    ) {
      id
    }
  }
`

export const UPDATE_MY_PROFILE = gql`
  mutation UpdateMyProfile(
    $user: UserInput!
  ) {
    updateMyProfile(
      user: $user
    ) {
      id
    }
  }
`

export const UPDATE_MY_PASSWORD = gql`
  mutation UpdateMyPassword(
    $oldPassword: String!
    $newPassword: String!
  ) {
    updateMyPassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
    )
  }
`

export const VERIFY_EMAIL = gql`
  mutation ActivateEmail($url: String!, $email: String!) {
    activateEmail(url: $url, email: $email)
  }
`

export const RESEND_ACTIVATION_EMAIL = gql`
  mutation ResendActivationEmail($email: String!) {
    resendActivationEmail(email: $email)
  }
`
