import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from 'theme'

const AvatarEditor = ({
  avatar,
  onChange,
}) =>
  <Wrapper>
    <Avatar
      alt="image"
      src={ avatar }
    />
    <Title>
      VOTRE AVATAR
    </Title>
    <Picker htmlFor="avatarPicker">
      Ajouter
    </Picker>
    <input
      accept="application/pdf, image/png, image/jpg"
      hidden
      id="avatarPicker"
      type="file"
      onChange={ onChange }
    />
  </Wrapper>

AvatarEditor.propTypes = {
  avatar: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Avatar = styled.img`
  width: 10rem;
  height: 10rem;
  margin-right: 0.5rem;
  margin-bottom: 0.9375rem;
  object-fit: cover;
  border-radius: 100%;
  box-shadow: 0 4px 9px 0 #0000007a;

  @media (width <= 1050px) {
    width: 5rem;
    height: 5rem;
  }
`

const Title = styled.span`
  margin-bottom: 1rem;
  font-family: 'Brother 1816';
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  color: ${colors.white};
`

const Picker = styled.label`
  padding: 0.7rem 1.9rem;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: bold;
  color: ${colors.lightGray};
  cursor: pointer;
  user-select: none;
  background-color: rgb(255 255 255 / 12%);
  border: 1px solid;
  border-radius: 4px;
`

export default AvatarEditor
