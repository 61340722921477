import { FC } from 'react'
import styled from 'styled-components'

type Props = {
  image: string;
  url?: string;
}

const PartnerCard: FC<Props> = ({ image, url }) => (
  url ? (
    <Link
      href={ url }
      rel="noreferrer"
      target='_blank'
    >
      <Figure>
        <Image
          alt={ image }
          src={ image }
        />
      </Figure>
    </Link>
  ) : (
    <Figure>
      <Image
        alt={ image }
        src={ image }
      />
    </Figure>
  )
)

export default PartnerCard

const Figure = styled.figure`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
  margin: 0;
`

const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const Image = styled.img`
  width: auto;
  max-height: 100%;
`
