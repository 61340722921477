import React from 'react'
import { ToastContainer as OriginalToastContainer } from 'react-toastify'
import CloseButton from './CloseButton'

const ToastContainer = () =>
  <OriginalToastContainer
    closeButton={ <CloseButton /> }
    draggablePercent={ 20 }
    hideProgressBar
    icon={ false }
  />

export default ToastContainer
