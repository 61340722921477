import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Icon } from 'components/atoms'

const ButtonContent = ({
  color,
  icon,
  iconSize,
  title,
  children,
}) =>
  icon !== null
    ? (
      <>
        <StyledIcon
          fill={ color }
          name={ icon }
          size={ iconSize !== null
          ? iconSize
          : '1em' }
        />
        <Title $color={ color }>
          {title}
        </Title>
      </>
    )
    :
    children

ButtonContent.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  icon: PropTypes.string,
  iconSize: PropTypes.string,
  title: PropTypes.string,
}

ButtonContent.defaultProps = {
  children: null,
  color: null,
  icon: null,
  iconSize: null,
  title: null,
}

const StyledIcon = styled(Icon)`
  && {
    margin-right: 1rem;
  }
`

const Title = styled.span`
  ${({ $color }) => $color != null
    ? `color: ${$color}`
    : null
}
`

export default ButtonContent
