import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MenuControls from './MenuControls'
import { colors } from 'theme'
import jwtPayloadExtractor from 'utils/jwtPayloadExtractor'
import Cookies from 'js-cookie'

const HeaderUser = ({
  avatar,
  username,
  slim,
  ...props
}) => {
  const [isOpen, setOpen] = useState(false)

  const handleToggle = () => {
    setOpen(!isOpen)
  }

  const authToken = Cookies.get('authToken')
  let isAdminMode = false
  if (authToken) {
    const payload = jwtPayloadExtractor(authToken)
    isAdminMode = payload && payload.admin
  }

  return (
    <Wrapper
      onClick={ handleToggle }
      { ...props }
    >
      <UserInfo>
        <Username $slim={ slim }>
          {username}
        </Username>
        {isAdminMode && <AdminMessage>&quot;Log As&quot; activé</AdminMessage>}
      </UserInfo>
      <Avatar
        alt="image"
        src={ avatar }
      />
      <MenuControls
        open={ isOpen }
        onClose={ handleToggle }
      />
    </Wrapper>
  )
}

HeaderUser.propTypes = {
  avatar: PropTypes.string.isRequired,
  slim: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
}

HeaderUser.defaultProps = {
  slim: false,
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 1rem;
  cursor: pointer;
  border: 1px solid ${colors.white};
  border-radius: 4px;
`

const avatarSize = '2.8125rem'

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0.5rem;
`

const Username = styled.span`
  margin-right: 0.9375rem;
  font-family: 'Brother 1816';
  font-size: ${({ $slim }) => `${$slim ? '0.875rem' : '1.125'}rem`};
  font-style: normal;
  font-weight: bold;
  color: ${({ $slim }) => $slim ? colors.midnightBlue : colors.white};

  @media (width <= 680px) {
    display: none;
  }
`

const Avatar = styled.img`
  width: ${avatarSize};
  height: ${avatarSize};
  margin-right: 0.5rem;
  object-fit: cover;
  border-radius: 100%;
  box-shadow: 0 4px 9px 0 #0000007a;
`

const AdminMessage = styled.span`
  padding: 0.25rem 0.5rem;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  font-weight: bold;
  color: ${colors.red};
  background-color: ${colors.white};
  border-radius: 4px;
`

export default HeaderUser
