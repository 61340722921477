import React from 'react'
import StoreButton from './StoreButton'
import styled from 'styled-components'
import { PLAY_STORE_APP_URL, APPLE_STORE_APP_URL } from 'constants/link'

const stores = [
  {
    availability: 'le Playstore',
    icon: 'android',
    url: PLAY_STORE_APP_URL,
  },
  {
    availability: 'l\'AppStore',
    icon: 'apple',
    url: APPLE_STORE_APP_URL,
  },
]

const StoresButtons = () =>
  <Wrapper>
    { stores.map((props, key) =>
      <StoreButton
        key={ key }
        { ...props }
      />,
    )}
  </Wrapper>

const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
`

export default StoresButtons
