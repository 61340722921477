import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { SUBSCRIPTION } from 'graphql/queries/user'
import { CartContext } from 'context/Cart'
import DrawerBottom from './DrawerBottom'

const MINIMUM_TEAM_LICENCES = 2

const Container = ({
  plan,
}) => {
  const navigate = useNavigate()

  const [licencesCountInit, setlicencesCountInit] = useState(false)
  const [minLicencesNumber, setMinLicencesNumber] = useState(MINIMUM_TEAM_LICENCES)
  const [form, setForm] = useState({
    licencesCount: {
      label: MINIMUM_TEAM_LICENCES,
      value: MINIMUM_TEAM_LICENCES,
    },
    coupon: null,
  })

  const { actions } = useContext(CartContext)

  useQuery(SUBSCRIPTION, {
    onCompleted: data => {
      if (!data?.me?.subscription || licencesCountInit) {
        return
      }

      if (data.me.subscription.quantity <= MINIMUM_TEAM_LICENCES) {
        return
      }

      setForm({
        ...form,
        licencesCount: {
          label: data.me.subscription.quantity,
          value: data.me.subscription.quantity,
        },
      })
      setMinLicencesNumber(data.me.subscription.quantity)
      setlicencesCountInit(true)
    },
  })

  const handleChange = (value, name) => {
    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSubmit = () => {
    const {
      licencesCount,
    } = form

    let licencesCountValue = licencesCount.value

    if (plan.metadata.threshold === 1) {
      licencesCountValue = 1
    }

    actions.setCart({
      licencesCount: licencesCountValue,
      plan,
    })

    window.scrollTo(0, 0)

    navigate('/payment')
  }

  return (
    <DrawerBottom
      form={ form }
      minLicencesNumber={ minLicencesNumber }
      plan={ plan }
      onChange={ handleChange }
      onSubmit={ handleSubmit }
    />
  )
}

Container.propTypes = {
  plan: PropTypes.shape({
    interval: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    metadata: PropTypes.shape({
      displayPrice: PropTypes.string.isRequired,
      taxType: PropTypes.string.isRequired,
      threshold: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
}

export default Container
