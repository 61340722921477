import { gql } from '@apollo/client'

export const AVAILABLE_PLANS = gql`
  query AvailablePlans {
    availablePlans {
      id
      title
      description
      amount
      interval
      metadata {
        taxType
        threshold
        displayPrice
        discount {
          title
          description
          levels {
            label
            licencesCount
            percentOff
          }
        }
      }
    }
  }
`

