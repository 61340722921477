export const TEAM_SUBSCRIPTION_ANNUAL = 'TEAM_SUBSCRIPTION_ANNUAL'

export const TEAM_MENSUAL = 'Team mensuel'

export const MONTH_INTERVAL = 'month'

export const defaultSubscriptionDetails = {
  trialPeriod: 'Essai gratuit de 14 jours sans engagement',
}

export const twoMonthsSubscriptionDetails = {
  trialPeriod: '2 mois d\'essai gratuit sans engagement',
}

export const threeMonthsSubscriptionDetails = {
  trialPeriod: '3 mois d\'essai gratuit sans engagement',
}
