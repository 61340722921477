import { colors } from 'theme'
import styled from 'styled-components'

const DataCollectionInfo = () => {
  return (
    <Wrapper>
      <b>DEFIMEDOC</b>
      {' '}
      collecte vos données personnelles pour vous permettre de créer et gérer votre compte sur le site internet
      {' '}
      <a href="https://defimedoc.fr"
        target="blank"
      >
        Defimedoc
      </a>
      {' '}
      afin d’accéder et d’utiliser ses fonctionnalités ainsi que celles de son application mobile dédiée [defimedoc].
      Pour plus d’informations sur l’utilisation de vos données personnelles et sur les modalités d’exercice de vos droits, nous vous invitons à consulter notre
      {' '}
      <b>
        <a href="/privacy-policy"
          target="blank"
        >
          Politique de protection des données personnelles
        </a>
      </b>
      .
    </Wrapper>
  )
}

const Wrapper = styled.p`
    margin: 1rem 0;
    font-family: Montserrat;
    font-size: 0.75rem;
    font-style: italic;
    line-height: 1rem;
    color: ${colors.midnightBlue};
`

export default DataCollectionInfo
