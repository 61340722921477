import React from 'react'
import styled from 'styled-components'
import { colors } from 'theme'
import AboutUsBackground from 'assets/images/landing/about-us-background.png'
import AboutUsBackgroundConnected from 'assets/images/landing/about-us-background-connected.png'
import { useQuery } from '@apollo/client'
import { USER } from 'graphql/queries/user'

const AboutUs = () => {
  const userQuery = useQuery(USER)
  const isLogged = Boolean(userQuery?.data?.me)

  return (
    <Wrapper id="about-us">
      <BackgroundTop className={ isLogged ? 'is-logged' : '' }>
        <BackgroundInner>
          <Title>
            Défimédoc est né de la volonté d’
            <TitleEmphasis>
              innover
            </TitleEmphasis>
            {'  pour la formation des professionnels de santé.'}
          </Title>
          <Row>
            <img
              alt="Florence Bontemps"
              height="315"
              src={ require('assets/images/florence.png') }
            />
            <DescriptionWrapper>
              <Description>
                « Directrice Scientifique du Moniteur des pharmacies pendant 10 ans, j&apos;ai supervisé 300 Cahiers
                Formation du Moniteur, concocté 10 modules e-learning de DPC, écrit et corrigé 10 éditions du
                &quot;Conseil à l&apos;Officine dans la poche&quot; Mais je sentais que vous aviez besoin d&apos;un outil
                plus pratique, plus rapide, plus ludique, plus convivial pour vous former et rester au top sur le
                médicament. C&apos;est ainsi qu&apos;est né Défimédoc, l&apos;innovation au service de la qualité des
                contenus. »
              </Description>
              <Signature>
                Florence Bontemps, Présidente de Défimédoc
              </Signature>
            </DescriptionWrapper>
          </Row>
        </BackgroundInner>
      </BackgroundTop>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
`

const BackgroundTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-top: 8rem;
  padding-bottom: 3rem;
  margin-top: 5rem;
  background-image: url(${AboutUsBackground});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

  &.is-logged {
    background-image: url(${AboutUsBackgroundConnected});
  }
`

const BackgroundInner = styled.div`
  padding: 0 10%;
  overflow: hidden;

  @media (width <= 900px) {
    padding: 0 5% 5%;
  }
`

const Title = styled.h2`
  margin: 0 5%;
  font-family: 'Brother 1816';
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 900;
  color: ${colors.white};
  text-align: center;
  white-space: pre-line;
`

const TitleEmphasis = styled.span`
  color: ${colors.lightGreen};
`

const Row = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 2rem;

  & > img {
    max-width: 100%;
  }

  @media (width <= 900px) {
    flex-wrap: wrap;

    & > img {
      margin: 0 auto;
    }
  }
`

const Description = styled.blockquote`
  margin-left: 2rem;
  font-family: Montserrat;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: normal;
  color: ${colors.white};

  @media (width <= 900px) {
    padding: 0 5%;
    margin-left: 0;
  }
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Signature = styled.blockquote`
  margin-top: 1rem;
  margin-left: 2rem;
  font-family: Montserrat;
  font-size: 1.375rem;
  font-style: italic;
  font-weight: bold;
  color: ${colors.white};

  @media (width <= 900px) {
    padding: 0 5%;
    margin-left: 0;
  }
`

export default AboutUs
