import React from 'react'
import styled from 'styled-components'
import { Button } from 'components/atoms'

const SatisfactionSurvey = () => (
  <Wrapper>
    <h3 className="text-subheading">Enquête de satisfaction</h3>
    <p className="text-content">
      Votre formation est terminée, qu&apos;en avez-vous pensé ?
    </p>
    <Button
      href="https://docs.google.com/forms/d/e/1FAIpQLSf4ClmUnPc1KD0qXTGSBF4OQUaw-CtqPHUQGMoDFp5i0_mPew/viewform"
      variant="primary"
    >
      Répondre à l&apos;enquête de satisfaction
    </Button>
    <p className="text-content conclusion">
      <em className="italic">
        Merci d&apos;avoir participé à cette formation proposée par Défimédoc.
        <br />
        Les Fiches l&apos;Essentiel au format pdf vous seront envoyées par mail avec votre attestation de DPC dès
        l&apos;enquête de satisfaction remplie.
      </em>
      <span>
        - L&apos;Équipe Défimédoc
      </span>
    </p>
  </Wrapper>
)

const Wrapper = styled.section`
  .conclusion {
    margin-top: 4rem;
    text-align: center;

    span {
      display: block;
      margin-top: 1rem;
      font-style: italic;
      text-align: right;
    }
  }
`

export default SatisfactionSurvey
