import React from 'react'
import styled from 'styled-components'
import ContactFooter from 'components/ContactFooter'
import { Button } from 'components/atoms'
import { PLAY_STORE_APP_URL, APPLE_STORE_APP_URL } from 'constants/link'

const AppPreview = () =>
  <BackgroundImage>
    <Image
      alt="aperçu application"
      height="518"
      src={ require('assets/images/landing/screens-app-bottom-connected.png') }
      width="492"
    />
    <Body>
      <StoresButtons>
        <AndroidButton
          href={ PLAY_STORE_APP_URL }
          icon="android"
          iconSize="1.5em"
          rel="noopener noreferrer"
          size="small"
          target="_blank"
          title="Télécharger"
          variant="primary"
        />
        <AppleButton
          href={ APPLE_STORE_APP_URL }
          icon="apple"
          iconSize="1.5em"
          rel="noopener noreferrer"
          size="small"
          target="_blank"
          title="Télécharger"
          variant="primary"
        />
      </StoresButtons>
      <ContactFooter lowAlpha={ true } />
    </Body>
  </BackgroundImage>

const BackgroundImage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url('${require('assets/images/landing/background-bottom-connected.png')}');
  background-size: cover;
`

const Image = styled.img`
  align-self: center;
  max-width: 100%;
  height: auto;
  margin-top: -15rem;
  margin-bottom: 1rem;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const StoresButtons = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-self: center;

  a {
    margin: 0 auto;
    margin-bottom: 5rem;

    @media (width <= 500px) {
      margin: 5% auto;
    }
  }
`

const AndroidButton = styled(Button)`
  && {
    margin-right: 1.75rem;

    @media (width <= 500px) {
      padding: 1.1rem 1rem;
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }
  }
`

const AppleButton = styled(Button)`
  && {
    @media (width <= 500px) {
      padding: 1.1rem 1.2rem;
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }
  }
`

export default AppPreview
