import { gql } from '@apollo/client'

export const SUBSCRIBE_STRIPE = gql`
  mutation SubscribeStripe(
    $planId: ID!
  ) {
    subscribeStripe(
      planId: $planId
    ) {
      id
    }
  }
`

export const SUBSCRIBE_TEAM_STRIPE = gql`
  mutation SubscribeTeamStripe(
    $planId: ID!
    $pharmacy: PharmacyInput!
    $quantity: Int!
  ) {
    subscribeTeamStripe(
      planId: $planId
      pharmacy: $pharmacy
      quantity: $quantity
    ) {
      id
    }
  }
`

export const REGISTER_PAYMENT_METHOD = gql`
  mutation registerPaymentMethod(
    $address: AddressInput
    $billingAddress: AddressInput
    $paymentMethodId: String!
  ) {
    registerPaymentMethod(
      billingAddress: $billingAddress
      address: $address
      paymentMethodId: $paymentMethodId
    ) {
      threeDSRedirectUrl
    }
  }
`

export const UPGRADE_SUBSCRIPTION_STRIPE = gql`
    mutation upgradeSubscriptionStripe(
        $subscriptionId: ID!,
        $planId: ID!,
        $quantity: Int,
        $pharmacy: PharmacyInput
    ) {
        upgradeSubscription(
            subscriptionId: $subscriptionId
            planId: $planId
            quantity: $quantity
            pharmacy: $pharmacy
        ) {
            id
        }
    }
`
