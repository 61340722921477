import { gql } from '@apollo/client'

export const SCORES = gql `
    query Scores ($usersId:[ID]!, $monthYears:[MonthYear]!) {
      aggregatedStatistics (usersId: $usersId, monthYears: $monthYears) {
        monthYear
        sumCalculatedScore
        averageQuizDone
        averageQuizValidated
        averageQuizRedone
      }
    }
`
