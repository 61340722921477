import React, { FC } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'
import { Badge as BadgeType } from '../../types/graphql'

const labels = {
  BRONZE: 'Bronze',
  SILVER: 'Argent',
  GOLDEN: 'Or',
  PLATINUM: 'Platine',
  DIAMOND: 'Diamant',
  TITANIUM: 'Titane',
  QUARTZ: 'Quartz',
  ADAMANTIUM: 'Adamantium',
  SAPPHIRE: 'Saphir',
  RUBY: 'Rubis',
  EMERALD: 'Emeraude',
  OBSIDIAN: 'Obsidienne',
  MITHRIL: 'Mithril',
}

type Props = {
  type: BadgeType;
}

const Badge: FC<Props> = ({ type }) => {
  if (type === null) {
    return <None>-</None>
  }

  return (
    <StyledBadge $type={ type }>
      {labels[type]}
    </StyledBadge>
  )
}

const resolveColor = (type: BadgeType) => {
  if ([
    BadgeType.SILVER,
    BadgeType.GOLDEN,
    BadgeType.DIAMOND,
    BadgeType.QUARTZ,
  ].includes(type)) {
    return colors.black
  }

  return colors.white
}

const resolveBackgroundColor = (type: BadgeType) => {
  switch (type) {
  case BadgeType.BRONZE:
    return colors.bronze
  case BadgeType.SILVER:
    return colors.silver
  case BadgeType.GOLDEN:
    return colors.gold
  case BadgeType.PLATINUM:
    return colors.platinum
  case BadgeType.DIAMOND:
    return colors.diamond
  case BadgeType.TITANIUM:
    return colors.titanium
  case BadgeType.QUARTZ:
    return colors.quartz
  case BadgeType.ADAMANTIUM:
    return colors.adamantium
  case BadgeType.SAPPHIRE:
    return colors.sapphire
  case BadgeType.RUBY:
    return colors.ruby
  case BadgeType.EMERALD:
    return colors.emerald
  case BadgeType.OBSIDIAN:
    return colors.obsidian
  case BadgeType.MITHRIL:
    return colors.mithril
  default:
    return colors.blueGray
  }
}

const StyledBadge = styled('span') <{ $type: BadgeType }>`
  display: inline - block;
  width: 3.5rem;
  padding: 6px 7px;
  font-family: Montserrat;
  font-size: 0.75rem;
  font-weight: 600;
  color: ${({ $type }) => resolveColor($type)};
  text-transform: uppercase;
  background-color: ${({ $type }) => resolveBackgroundColor($type)};
  border-radius: 8px;
`

const None = styled.span`
  font-family: 'Brother 1816';
  font-weight: 700;
  color: ${colors.blueGray};
`

export default Badge
