import React, { useContext } from 'react'
import { rgba } from 'polished'
import styled from 'styled-components'
import { Icon } from 'components/atoms'
import { ModalContext } from 'context/Modal'
import { colors, layout } from 'theme'

const ModalThreeDS = () => {
  const { state, actions } = useContext(ModalContext)

  const {
    isThreeDSModalOpen,
  } = state

  const handleModalClose = actions.toggleThreeDSModal

  return (
    <Wrapper
      $isOpen={ isThreeDSModalOpen }
      id="threeDSModal"
      onClick={ handleModalClose }
    >
      <Overlay $isOpen={ isThreeDSModalOpen }>
        <ModalWrapper $isOpen={ isThreeDSModalOpen }
          id="threeDSModalContent"
        >
          <StyledIcon
            fill={ colors.white }
            name="close"
            size="1.7rem"
            onClick={ handleModalClose }
          />
        </ModalWrapper>
      </Overlay>
    </Wrapper>
  )
}

const toggleDuration = 200

const Wrapper = styled.div`
  position: fixed;
  z-index: ${layout.modalZIndex};
  display: flex;
  flex-shrink: 0;
  ${({ $isOpen }) => !$isOpen ? 'visibility: hidden;' : ''}
  width: 50%;
  height: 100%;
`

const Overlay = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: ${rgba(colors.midnightBlue, 0)};
  transition: background-color ${toggleDuration}ms ease-in-out;
  transition-delay: ${toggleDuration / 2}ms;
  ${({ $isOpen }) => $isOpen && `
    background-color: ${rgba(colors.midnightBlue, 0.4)};
  `};

  @media (width <= 820px) {
    width: 100%;
  }
`

const ModalWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  height: 75%;
  min-height: 15rem;
  font-family: 'Brother 1816';
  color: ${colors.darkBlue};
  text-align: center;
  background-color: ${colors.white};
  border-radius: 5px;
  box-shadow: 0 3px 24px 0 rgb(0 0 0 / 43%);
`

const StyledIcon = styled(Icon)`
  && {
    position: absolute;
    top: -45px;
    right: -120px;
    padding: 1.8125rem;
    cursor: pointer;
    background-color: ${colors.lightGreen};
    border-radius: 100%;

    @media (width <= 1090px) {
      top: 0;
      right: 0;
    }

    @media (width <= 550px) {
      padding: 0.5rem;
      margin-right: 0.5rem;
    }
  }
`

export default ModalThreeDS
