import React from 'react'
import styled from 'styled-components'
import { colors } from 'theme'
import {
  Separator,
} from 'components/landing'
import {
  CheckList,
} from 'components/atoms'
import { FoldableFAQList } from '../index'
import { PageMainWrapper } from '../style'

const Main = () => {
  return (
    <Wrapper>
      <Introduction>
        <p>
          Depuis le 1er avril 2024,
          <br />
          l&apos;action &ldquo;Défimédoc&rdquo; est labellisée par le Conseil National de la Pharmacie (CNP) au titre du parcours DPC du CNP Pharmacie.
          <br />
          Cette labellisation porte sur l&apos;axe &ldquo;actualiser les connaissances et les compétences&rdquo; et correspond à&nbsp;:
        </p>
        <ul>
          <li>un abonnement à Défimédoc pendant une durée d&apos;un an (100 Quiz)</li>
          <li>ET la validation de 60% des Quiz minimum</li>
        </ul>
      </Introduction>
      <div>
        <p>Date de mise à jour: Avril 2024</p>
        <h3 className="text-subheading">En pratique :</h3>
        <p>
          Vous validerez la partie
          <em>
            {' '}
            &ldquo;actualisation des connaissances&rdquo; de votre DPC
            {' '}
          </em>
          au bout d’un an d’abonnement à Défimédoc sous réserve&nbsp;:
        </p>
        <CheckList items={ checkItems } />
        <h3 className="text-subheading">Important :</h3>
        <p>
          La labellisation par le CNP
          <em>
            {' '}
            ne concerne que les pharmaciens officinaux
            {' '}
          </em>
          (titulaires et adjoints). Le CNP ne labellise pas d&apos;actions pour les préparateurs en pharmacie.
        </p>
        <p>
          L&apos;abonnement à Défimédoc
          <em>
            {' '}
            n&apos;est pas pris en charge par l&apos;ANDPC ou par l&apos;OPCO
          </em>
          . Il n&apos;est pas prévu d&apos;indemnité pour les pharmaciens titulaires.
        </p>
        <FoldableFAQList />
        <Separator />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled(PageMainWrapper)`
  padding: 0 1rem 3rem;
  color: ${colors.darkBlue};

  em {
    font-style: normal;
    color: ${colors.hanBlue};
  }

  h2 {
    text-align: center;
  }

  h3 {
    margin: 6rem 0 3rem;
    color: ${colors.purple};
  }

  p.bottom-block {
    text-align: center;

    em {
      font-weight: bold;
      color: ${colors.darkBlue};
    }
  }

  li {
    text-align: left;
  }

  ${Separator} {
    margin: auto;
  }

  @media (width <= 1050px) {
    .cards-wrapper {
      flex-direction: column;
      align-items: center;

      > *:first-child {
        margin-bottom: 2rem;
      }
    }
  }
`

const checkItems = [
  {
    key: '1',
    node: (
      <span>
        D&apos;avoir été abonné
        <em> pendant 12 mois consécutifs</em>
        , en abonnement mensuel sans discontinuité ou en abonnement annuel. L&apos;abonnement peut être
        <em> un abonnement individuel ou un abonnement d’équipe</em>
        . Il est possible d&apos;enchaîner deux statuts, par exemple 3 mois d&apos;abonnement individuel et 9 mois d&apos;abonnement en équipe, s&apos;il n’y a pas de discontinuité entre les deux abonnements.
      </span>
    ),
  },
  {
    key: '2',
    node: (
      <span>
        <em>D’avoir validé au minimum 60% des Quiz pendant l&apos;année</em>
        . Un Quiz validé correspond à un score minimum de 70/100. Chaque Quiz peut être refait autant de fois que souhaité.
      </span>
    ),
  },
]

const Introduction = styled.div`
  font-family: 'Brother 1816';
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 55px;
  color: ${colors.darkBlue};
  text-align: center;

  em {
    color: ${colors.purple};
  }

  p {
    margin-bottom: 0;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 55px;
  }

`

export default Main
