import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { logError } from 'logService'
import LicenceInput from './LicenceInput'
import {
  ADD_TEAM_MEMBER,
  DELETE_TEAM_MEMBER,
} from 'graphql/mutations/user'
import { LICENCES_STATUS } from 'graphql/queries/user'
import { isEmail } from 'utils/regex'
import { ModalContext } from 'context/Modal'
import { useMutation } from '@apollo/client'

const Container = ({
  lastname,
  firstname,
  email,
  ownerEmail,
  ...props
}) => {
  const [addTeamMember, { loading: addTeamMemberLoading }] = useMutation(ADD_TEAM_MEMBER)
  const [deleteTeamMember] = useMutation(DELETE_TEAM_MEMBER)

  const [sent, setSent] = useState(email.length !== 0)
  const [form, setForm] = useState({
    lastname,
    firstname,
    email,
  })
  const [errors, setErrors] = useState({})

  const { actions } = useContext(ModalContext)
  const { closeModal, setModal } = actions

  const clearForm = () => {
    setForm({
      lastname: '',
      firstname: '',
      email: '',
    })
    setSent(false)
  }

  const handleChange = (value, name) => {
    let updatedErrors = { ...errors }

    updateFormValue(value, name)

    setErrors({
      ...updatedErrors,
      [name]: null,
    })
  }

  const updateFormValue = (value, name) => {
    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (sent) {
      return
    }

    const {
      email,
      firstname,
      lastname,
    } = form

    const errors = {}

    if (!isEmail.test(email)) {
      errors.email = 'Champ incorrect'
    }
    if (firstname.length === 0) {
      errors.firstname = 'Champ obligatoire'
    }
    if (lastname.length === 0) {
      errors.lastname = 'Champ obligatoire'
    }

    setErrors(errors)
    const hasError = Object.values(errors).some(error => typeof error === 'string')

    if (!hasError) {
      try {
        const { data } = await addTeamMember({
          variables: {
            email,
            firstname,
            lastname,
          },
        })

        if (data.addTeamMember.id !== undefined) {
          setSent(true)
        }
      } catch (e) {
        let message = ''
        if (e.networkError.result.errors[0].message === 'This email is already used.') {
          message = 'Ce compte existe déjà ! Contactez le Service Client pour mettre en place l\'abonnement : contact@defimedoc.fr'
        } else {
          message = 'Oups, une erreur est survenue'
        }
        toast.error(message, {
          position: 'top-center',
        })
        logError('GRAPHQL', 'addTeamMember', e)
      }
    }
  }

  const handleDeleteClick = async () => {
    setModal({
      title: 'Supprimer un bénéficiare',
      details: `Si vous supprimez ce bénéficiaire, il cessera d'avoir accès aux Quiz Défimédoc immédiatemment.
                Vous pourrez le remplacer par un autre bénéficiaire, qui aura immédiatemment accès aux Quiz publiés.
                `,
      cancel: {
        text: 'Annuler',
        handleClick: closeModal,
      },
      validate: {
        text: 'Supprimer le bénéficiaire',
        handleClick: handleDelete,
      },
    })
  }

  const handleDelete = async e => {
    e.preventDefault()

    const {
      email,
    } = form

    try {
      await deleteTeamMember({
        variables: {
          email,
        },
        refetchQueries: [{
          query: LICENCES_STATUS,
        }],
      })
      clearForm()
      toast.success('Bénéficiaire supprimé. Vous pouvez dès à présent en ajouter un nouveau.', {
        position: 'top-center',
      })
    } catch (e) {
      toast.error('Oups, une erreur est survenue', {
        position: 'top-center',
      })
      logError('GRAPHQL', 'deleteTeamMember', e)
    }
    closeModal()
  }

  // The owner cannot be deleted from the subscription team members
  const disableDelete = ownerEmail === email

  return (
    <LicenceInput
      disableDelete={ disableDelete }
      errors={ errors }
      form={ form }
      loading={ addTeamMemberLoading }
      sent={ sent }
      onChange={ handleChange }
      onDelete={ handleDeleteClick }
      onSubmit={ handleSubmit }
      { ...props }
    />
  )
}

Container.propTypes = {
  email: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired,
  lastname: PropTypes.string.isRequired,
  ownerEmail: PropTypes.string.isRequired,
}

export default Container
