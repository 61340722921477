import React from 'react'
import styled from 'styled-components'
import { USER } from 'graphql/queries/user'
import Badge from '../../my-team/Badge'
import { colors } from 'theme'
import { useQuery } from '@apollo/client'

const MyStats = () => {
  const userQuery = useQuery(USER)

  if (userQuery.loading || !userQuery.data?.me) {
    return null
  }

  const dateFormat = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }

  const monthlyStatistic = userQuery.data?.me?.monthlyStatistic

  return (
    <Wrapper>
      <Title>
        <MyBadgeWrapper>
          <Badge type={ monthlyStatistic?.badge } />
        </MyBadgeWrapper>
      </Title>
      <Title>
        Nb quiz fait(s)
      </Title>
      <Data>
        { `${monthlyStatistic?.totalQuizDone} / ${monthlyStatistic?.totalQuizUnlocked}` }
      </Data>
      <Title>
        Dernier quiz fait
      </Title>
      <Data>
        { userQuery?.data?.me?.monthlyStatistic?.lastQuizDate === null
          ? 'NC'
          : new Date(Date.parse(userQuery?.data?.me?.monthlyStatistic?.lastQuizDate)).toLocaleDateString('fr-FR', dateFormat)
        }
      </Data>
    </Wrapper>
  )
}

const Wrapper = styled.div `
  display: flex;
  flex: 0 0 10rem;
  flex-direction: column;
`
const MyBadgeWrapper = styled.span `
  border-radius: 8px;
  box-shadow: 0 4px 9px 0 #0000007a;
`

const Title = styled.span`
  display: block;
  margin-top: 1rem;
  font-family: 'Brother 1816';
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  color: ${colors.white};
`
const Data = styled.span`
  display: block;
  font-family: 'Brother 1816';
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  color: ${colors.white};
`

export default MyStats
