import React from 'react'
import { useQuery } from '@apollo/client'
import styled from 'styled-components'
import { LICENCES_STATUS } from 'graphql/queries/user'
import LicenceInput from './LicenceInput'

const Licences = () => {
  const { data, loading } = useQuery(LICENCES_STATUS)

  if (loading || data === undefined) {
    return null
  }

  const { subscription } = data.me
  const invitations = subscription?.invitations || []

  const { teamMembers, quantity } = subscription

  const members = teamMembers.map(member => ({
    firstname: member.firstname,
    lastname: member.lastname,
    email: member.email,
  }))

  const licences = members.concat(invitations?.map((_, index) => {
    const {
      lastname,
      firstname,
      email,
    } = invitations[index]

    return {
      lastname,
      firstname,
      email,
    }
  }))

  if (licences.length < quantity) {
    [...Array(quantity - licences.length)].forEach(() => {
      licences.push({
        lastname: '',
        firstname: '',
        email: '',
      })
    })
  }

  return (
    <Wrapper>
      {licences.map(({
        lastname,
        firstname,
        email,
      }, index) =>
        <LicenceInput
          email={ email }
          firstname={ firstname }
          index={ index }
          key={ index }
          lastname={ lastname }
          ownerEmail={ data.me.email }
        />,
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
`

export default Licences
