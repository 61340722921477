import React from 'react'
import PropTypes from 'prop-types'
import { Logo, NavWrapper } from './style'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import NavItemAnchor from './NavItemAnchor'

const Nav = ({ navItems, children, ...props }) => {
  const navigate = useNavigate()

  return (
    <NavWrapper { ...props }>
      <Icon
        onClick={ () => navigate('/') }
      >
        <Logo />
      </Icon>
      <ul>
        { navItems.map(navItem =>
          <NavItemAnchor
            href={ navItem.href }
            key={ navItem.href }
          >
            {navItem.content}
          </NavItemAnchor>,
        )}
      </ul>
      { children }
    </NavWrapper>
  )
}

const Icon = styled.div`
  cursor: pointer;
`

Nav.propTypes = {
  children: PropTypes.node,
  navItems: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
  })).isRequired,
}

Nav.defaultProps = {
  children: null,
}

export default Nav
