import React from 'react'
import styled from 'styled-components'
import { Button, Icon } from 'components/atoms'
import { downloadIpTemplate, downloadIpExample } from 'utils/file'
import { APPLE_STORE_APP_URL, PLAY_STORE_APP_URL } from 'constants/link'
import { USER } from 'graphql/queries/user'
import { useQuery } from '@apollo/client'
import { formatISODate } from 'utils/date'

const Main = () => {
  const userQuery = useQuery(USER)
  const startDate = formatISODate(userQuery?.data?.me?.dpcAccess?.startDate)
  const endDate = formatISODate(userQuery?.data?.me?.dpcAccess?.endDate)

  const VIDEO_URL = 'https://www.youtube.com/watch?v=T-1SgoM4IEU'
  const VIDEO_URL_EMBED = VIDEO_URL.replace('watch?v=', 'embed/')

  return (
    <Wrapper>
      <section className="summary">
        <h2 className="text-heading">Déroulé de votre formation</h2>
        <p className="text-content">
          Vous êtes inscrit à la formation
          {' '}
          <em>
            &quot;Repérer le risque iatrogène et optimiser les interventions pharmaceutiques&quot;
          </em>
          . Durée de la formation : 4 mois.
        </p>
        <p className="text-content">
          Votre espace formation est actif du
          {' '}
          <em>{startDate}</em>
          {' '}
          au
          {' '}
          <em>{endDate}</em>
          .
        </p>
        <article>
          <h3 className="text-subheading">1. Test pré-formation</h3>
          <p className="text-content">
            Vous avez terminé votre Test pré-formation. Ce test vous sera proposé de nouveau à la fin de la formation.
            Le taux d&apos;amélioration sera un reflet des acquis de la formation.
          </p>
          <p className="text-content">
            <em> Vous pouvez maintenant démarrer vos Quiz !</em>
          </p>
        </article>
        <article>
          <h3 className="text-subheading">2. Accès aux Quiz, aux Fiches l’Essentiel et aux espaces de discussion</h3>
          <p className="text-content">
            Le contenu pédagogique (Fiches l&apos;Essentiel, Quiz, Scores, Dialogue avec le responsable pédagogique et
            entre apprenants) est disponible via
            {' '}
            <em>l&apos;application mobile : Défimédoc</em>
            .
            <br />
            Vous pouvez télécharger l&apos;application Défimédoc gratuitement sur AppStore ou PlayStore et vous
            connecter avec votre mail et mot de passe.
            <br />
            L&apos;accès à votre compte est strictement individuel.
            Ne divulguez pas votre mot de passe.
          </p>
          <div className="buttons-wrapper">
            <div className="button-wrapper">
              <span>Disponible sur le Playstore</span>
              <Button
                href={ PLAY_STORE_APP_URL }
                variant="primary"
              >
                <Icon name="android" />
                Télécharger
              </Button>
            </div>
            <div className="button-wrapper">
              <span>Disponible sur l&apos;iStore</span>
              <Button
                href={ APPLE_STORE_APP_URL }
                variant="primary"
              >
                <Icon name="apple" />
                Télécharger
              </Button>
            </div>
          </div>
          <div>
            <h4 className="text-content">Les Quiz :</h4>
            <p className="text-content">
              Un nouveau Quiz est mis à disposition
              {' '}
              <em>2 fois par semaine dans l&apos;application Défimédoc</em>
              .
              Le Quiz reste disponible tant que vous ne l&apos;avez pas fait et peut être refait à volonté.
            </p>
            <p className="text-content">
              Chaque Quiz est noté sur 100. Les Quiz sont validés si le score est ≥ 70 points/100.
              Votre dernier score efface le score précédent.
            </p>
            <p className="text-content">
              <em>
                Vous devez avoir fait tous les Quiz prévus dans votre programme de formation pour valider votre
                formation
              </em>
              , mais il n&apos;y a pas de score minimum à
              avoir sur chaque Quiz. Nous vous conseillons toutefois de valider tous vos Quiz (score ≥ 70 points/100).
            </p>
            <p className="text-content">
              <em className="italic">
                Le temps passé sur chaque session de Quiz est enregistré directement dans l&apos;application.
                <br />
                Un cumul du temps passé est transmis aux organismes financeurs.
              </em>
            </p>
          </div>
          <div>
            <h4 className="text-content">Les Fiches l&apos;Essentiel :</h4>
            <p className="text-content">
              Les Fiches de synthèse l&apos;Essentiel sont disponibles avec leur bibliographie
              {' '}
              <em>
                dès que le
                Quiz est terminé
              </em>
              .
              <br />
              Les fiches sont accessibles dans votre application tout au long de votre formation.
              <br />
              Elles seront disponibles en pdf à la fin de votre formation.
            </p>
          </div>
          <div>
            <h4 className="text-content">Les espaces de discussion : </h4>
            <p className="text-content">
              Chaque thématique de Quiz vous ouvre un espace de discussion. Vous pouvez échanger entre apprenants sur
              vos propres cas ou sur vos propres interventions.
            </p>
          </div>
        </article>
        <article>
          <h3 className="text-subheading">3. Amélioration des pratiques et Interventions pharmaceutiques</h3>
          <p className="text-content">
            <em className="italic">
              Cette partie est à suivre et à remplir selon vos disponibilités, en une ou plusieurs fois, au cours de
              votre formation.
            </em>
            <br />
            Elle vous présente comment améliorer le bon usage du médicament, en lien avec les Quiz proposés dans
            l&apos;application Défimédoc.
          </p>
          <div>
            <h4 className="text-content">Qu&apos;est ce qu&apos;une Intervention Pharmaceutique ?</h4>
            <div className="video-wrapper">
              <iframe
                allow="fullscreen; picture-in-picture"
                allowFullScreen
                frameBorder="0"
                src={ VIDEO_URL_EMBED }
              >
              </iframe>
            </div>
            <a
              className="video-link text-content"
              href={ VIDEO_URL }
              rel="noreferrer"
              target="_blank"
            >
              Voir la vidéo
            </a>
            <h5 className="text-description">Documents à télécharger</h5>
            <div className="buttons-wrapper">
              <div className="button-wrapper">
                <span>Modèle d’IP à télécharger (Document SFPC)</span>
                <Button
                  variant="primary"
                  onClick={ downloadIpTemplate }
                >
                  Modèle d&apos;IP
                </Button>
              </div>
              <div className="button-wrapper">
                <span>Exemples d&apos;IP</span>
                <Button
                  variant="primary"
                  onClick={ downloadIpExample }
                >
                  Exemples d&apos;IP
                </Button>
              </div>
            </div>
            <h4 className="text-content">Décrivez vos propres interventions</h4>
            <p className="text-content">
              <em className="italic">
                Cette partie est obligatoire pour valider votre formation.
                Vous pouvez remplir cette partie en plusieurs fois, tout au long de l&apos;avancement de votre
                formation.
              </em>
              <br />
              Décrivez de façon simple au minimum une intervention (IP) que vous avez réalisée, comme par exemple
              repérer et intervenir sur une erreur de posologie, un effet indésirable, une contre-indication…
              Nous vous ferons un retour par mail sur cette IP et ajouterons dans les exemples quelques IP anonymisées.
            </p>
            <Button
              href="https://forms.gle/VpXykLGv1ofr8CQx5"
              variant="primary"
            >
              Décrire mes interventions
            </Button>
          </div>
        </article>
        <article>
          <h3 className="text-subheading">4. Test post-formation</h3>
          <p className="text-content">
            <em className="italic">
              Ce Test final est obligatoire pour valider votre formation.
              Le taux d&apos;amélioration sera un reflet des acquis de compétences au cours de la formation.
              Le Test Post-Formation vous sera envoyé courant décembre par mail, une fois l&apos;ensemble des Quiz
              obligatoires faits.
            </em>
            <br />
            Le taux d&apos;amélioration sera un reflet des acquis de compétences au cours de la formation.
            Il est constitué de 20 cas pratiques.
          </p>
        </article>
      </section>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  h5 {
    margin-bottom: 1rem;
    font-weight: bold;
  }

  .summary {
    text-align: center;
  }

  button svg {
    width: 25px;
    height: 25px;
    margin-right: 0.75rem;
  }

  .buttons-wrapper {
    display: flex;

    div:first-of-type {
      margin: 0 1rem 0 0;
    }
  }

  .button-wrapper span {
    display: block;
    margin-bottom: 0.5rem;
    font-family: Montserrat;
    font-size: 0.75rem;
  }

  .video-wrapper {
    width: 960px;
    height: 540px;
    margin: 0 auto 1.5rem;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .video-link {
    display: none;
  }

  @media (width <= 1200px) {
    .video-wrapper {
      width: 675px;
      height: 380px;
    }
  }

  @media (width <= 800px) {
    .buttons-wrapper {
      flex-direction: column;

      div:first-of-type {
        margin: 0 0 1rem;
      }
    }

    .video-wrapper {
      width: 500px;
      height: 280px;
    }
  }

  @media (width <= 600px) {
    .video-wrapper {
      display: none;
    }

    .video-link {
      display: block;
      margin-bottom: 2rem;
      font-size: 1.25rem;
      font-weight: bold;
    }
  }
`

export default Main
