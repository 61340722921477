import { gql } from '@apollo/client'

export const DAILY_QUESTION = gql`
  query DailyQuestion {
    dailyQuestion {
      id
      title
      answer
    }
  }
`
