import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'components/atoms'
import { colors } from 'theme'
import { TEAM_SUBSCRIPTION_ANNUAL } from 'constants/subscription'
import { useQuery } from '@apollo/client'
import { SUBSCRIPTION } from 'graphql/queries/user'

const CurrentSubscription = ({
  automaticRenewal,
  isOwner,
  licenceCount,
  platform,
  subscriptionName,
  onChange,
}) => {
  const { data } = useQuery(SUBSCRIPTION)
  const {
    plan,
  } = data.me.subscription

  return (
    <Wrapper>
      <Top>
        <Title>
          MON ABONNEMENT
        </Title>
        {
          platform === 'stripe' && plan.id !== TEAM_SUBSCRIPTION_ANNUAL ?
            <Button
              href='/me/upgrade'
              variant="tertiary"
            >
              Modifier mon abonnement
            </Button>
          : null
        }
      </Top>
      <LicenceTypeAndCount>
        {`Abonnement ${subscriptionName === 'Liberté' ? `${subscriptionName} - Mensuel` : subscriptionName}${
          licenceCount > 1
            && isOwner
            ? ` - ${licenceCount} licences`
            : ''
        }`}
      </LicenceTypeAndCount>
      {
        isOwner ?
          <AutomaticRenewal>
            {(
              function() {
                switch (platform) {
                  case 'stripe':
                    return (
                      <CancelButton disabled={ !automaticRenewal }
                        size="medium"
                        variant="primary"
                        onClick={ onChange }
                      >
                        { automaticRenewal ? 'Annuler mon abonnement' : 'Abonnement annulé' }
                      </CancelButton>
                    )
                  case 'apple':
                    return (
                      <CancelInstruction>Pour annuler votre abonnement, vous devez modifier vos paramètres d&apos;abonnement dans votre compte Apple Store</CancelInstruction>
                    )
                  case 'google':
                    return (
                      <CancelInstruction>Pour annuler votre abonnement, vous devez modifier vos paramètres d&apos;abonnement dans votre compte Google Play</CancelInstruction>
                    )
                  default:
                    return null
                }
              })()
            }
          </AutomaticRenewal>
        : null
      }
    </Wrapper>
  )
}

CurrentSubscription.propTypes = {
  automaticRenewal: PropTypes.bool.isRequired,
  isOwner: PropTypes.bool.isRequired,
  licenceCount: PropTypes.number.isRequired,
  platform: PropTypes.string.isRequired,
  subscriptionName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Top = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const Title = styled.span`
  margin-bottom: 1rem;
  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  color: ${colors.darkGray};
  opacity: 0.5;
`

const LicenceTypeAndCount = styled.h3`
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: 'Brother 1816';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 900;
  color: ${colors.midnightBlue};
`

const AutomaticRenewal = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
`

const CancelButton = styled(Button)`
  && {
    padding: 0.83rem 1.5rem;
    font-size: 0.8438rem;
    background-color: ${colors.pink};
    background-image: none;
    border-radius: 0;
    box-shadow: none;
  }
`

const CancelInstruction = styled.h3`
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: 'Brother 1816';
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  color: ${colors.darkGray};
`

export default CurrentSubscription
