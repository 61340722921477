import React, { useState } from 'react'
import PropTypes from 'prop-types'

const initialState = {
  isOpen: false,
  plan: null,
}

const DrawerContext = React.createContext()

const DrawerProvider = ({ children }) => {
  const [state, setState] = useState({
    ...initialState,
  })

  const toggleDrawer = () => {
    setState({
      ...state,
      isOpen: !state.isOpen,
    })
  }

  const setPlan = plan => {
    setState({
      ...state,
      plan,
      isOpen: !state.isOpen,
    })
  }

  return (
    <DrawerContext.Provider
      value={ {
        state,
        actions: {
          toggleDrawer,
          setPlan,
        },
      } }
    >
      {children}
    </DrawerContext.Provider>
  )
}

DrawerProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DrawerProvider
export { DrawerContext }
