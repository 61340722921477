import React from 'react'
import Markdown from 'components/atoms/Markdown'
import styled from 'styled-components'
import PolicyLayout from 'components/layouts/PolicyLayout'

const PersonalData = () =>
  <PolicyLayout>
    <Title>Charte relative à la protection des données personnelles</Title>
    <Markdown>
      {
      `
_**1. Pourquoi cette charte ?**_

Dans le cadre de la navigation sur notre site internet à l’adresse [defimedoc.fr](https://www.defimedoc.fr/), de la souscription ou de l’utilisation de nos produits et services par ce biais ou par le biais de notre application dédiée **Défimédoc** (ensemble ci-après dénommé « la Plateforme »), nous serons amenés à collecter des données personnelles vous concernant. Une donnée personnelle désigne toutes les données qui permettent d’identifier un individu directement ou indirectement.

Cette charte a pour objet de vous informer sur les moyens que nous mettons en œuvre pour collecter et utiliser vos données personnelles, dans le respect le plus strict de vos droits. Nous vous indiquons à ce sujet que nous nous conformons, dans la collecte et la gestion de vos données personnelles, à la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, dans sa version en vigueur, ainsi qu’au Règlement général de protection des données n°2016/679 du 27 avril 2016.

_**2. Qui collecte vos données ?**_

La personne qui prend la décision de collecter et d’utiliser vos données personnelles est la société DEFIMEDOC, société par actions simplifiée, immatriculée au Registre du commerce et des sociétés de Nanterre sous le numéro SIREN 852 559 822, dont le siège social est situé 20 bis avenue Joffre – 92250 La Garenne-Colombes (dénommée dans le cadre des présentes « **Nous** »).

_**3. Quelles données sont collectées ?**_

Dans le cadre de l’utilisation de la Plateforme, nous collectons directement auprès de vous les données suivantes :

- **Informations permettant votre identification** lors de l’utilisation du formulaire d’inscription : nom de famille, prénom, pseudonyme, profession, numéro RPPS, adresse email, visuel ou photographie, ainsi qu’un justificatif professionnel ou d’étudiant de santé (carte de l’ordre, copie de diplôme, carte étudiant) en cas d’absence de numéro RPPS ;

- **Information bancaire** : relevé d’identité bancaire ou empreinte carte bancaire selon le choix de l'abonné ;

- **Toutes informations que vous choisirez de nous communiquer.**

Lors de votre navigation sur la Plateforme, nous sommes également susceptibles de collecter des données personnelles vous concernant de manière automatique par l’intermédiaire des cookies techniques et traceurs que nous déposons dans votre terminal. Les données collectées sont les suivantes :

- **Informations d’utilisation des outils et fonctionnalités de notre Plateforme**: nous collectons des informations concernant vos interactions avec la Plateforme et notamment, les pages ou les contenus consultés sur le site, les liens sur lesquels vous avez cliqué, le nombre et les catégories de quiz effectués, les résultats aux quiz effectués (score cumulé, score moyen, progression).

- **Informations de connexion et relatives aux équipements et appareils que vous utilisez pour vous connecter à notre Plateforme** : nous collectons les données de connexion d’appareils que vous utilisez, et notamment, votre adresse IP, votre identifiant de smartphone le cas échéant, les dates et heures de connexion, les données relatives au matériel et aux logiciels informatiques utilisés, les identifiants uniques, les données de plantage, les pages visualisées ou affichées avant ou après s’être connecté à la Plateforme.

Pour plus d’information sur les cookies et traceurs que nous utilisons et le paramétrage de ces cookies, nous vous invitons à consulter notre politique de gestion des cookies accessible [ici](/cookie-policy).

_**4. Pourquoi nous collectons vos données ?**_

Vos données personnelles sont collectées et utilisées pour répondre à un ou plusieurs des **objectifs (i à xi) suivants** :

- (i) Gérer votre accès à la Plateforme et répondre à toute demande concernant l’accès à cette Plateforme ;

- (ii) Gérer le traitement des formulaires d’inscription, et plus largement, effectuer les opérations relatives à la gestion et au suivi de nos relations avec nos clients et les utilisateurs de notre Plateforme, et notamment le suivi de la bonne exécution des produits et services proposés par ce biais ainsi que du contrat qui nous lie ;

- (iii) Constituer un fichier de contacts de nos clients et de prospects éventuels ;

- (iv) Adresser des messages informatifs relatifs à notre actualité et/ou toute évaluation de notre Plateforme ainsi que des produits et services en découlant. Dans le cas où vous ne le souhaiteriez pas, nous vous donnons la faculté d’exprimer votre refus à ce sujet lors de la collecte de vos données ;

- (v) Optimiser le bon fonctionnement, l’amélioration permanente de la Plateforme et de ses fonctionnalités ainsi que l’efficacité de nos produits et services proposés par ce biais ;

- (vi) Gérer la gestion des avis des personnes sur la Plateforme et les produits et services en découlant ;

- (vii) Élaborer des statistiques d’utilisation et de fréquentation de notre Plateforme ;

- (viii) Gérer les éventuels contentieux quant à l’utilisation de notre Plateforme et des produits et services en découlant ;

- (ix) Gérer vos demandes de droit d’accès, de rectification et d’opposition, d’effacement, de limitation, de portabilité ou le droit d’introduire une réclamation devant l’autorité compétente ;

- (x) Respecter nos obligations légales et réglementaires ;

- (xi) Effectuer les opérations relatives à la gestion comptable des abonnements et de la facturation.

Lors de la collecte de vos données personnelles, nous vous informons si certaines données doivent être obligatoirement renseignées ou si elles sont facultatives. Nous vous indiquons également quelles sont les conséquences éventuelles d’un défaut de réponse.

Nous précisons enfin que nous n’utilisons jamais vos données pour prendre des décisions automatisées vous concernant.

_**5. Comment nous justifions la collecte et l’utilisation de vos données ?**_

Nous ne pouvons utiliser vos données que si la loi nous autorise à le faire. Dans ce cadre, le législateur a prévu six cas de figure dans lesquels l’utilisation des données est justifiée (appelés « **bases légales** »). L’utilisation de vos données personnelles se base toujours sur l’une de ces six bases légales.

- Concernant les objectifs (i) et (ii), le traitement de vos données est nécessaire à l’utilisation des produits et services auxquels vous avez accès par le biais de la Plateforme. Dans ce cadre, le fondement de l’utilisation de vos données personnelles est l’exécution du contrat qui nous lie. Votre consentement exprès à cette collecte n’est donc pas requis.

- Concernant les objectifs (iii) à (viii), l’utilisation de vos données relève de l’intérêt légitime de notre entreprise et n’est donc pas soumise à l’obtention préalable de votre consentement.

- Concernant les objectifs (ix) à (xi), vos données sont collectées et utilisées pour respecter nos obligations légales. Votre consentement n’est pas requis pour la réalisation de ce traitement.

Pour toute utilisation de vos données qui ne serait pas prévue par la présente charte, nous solliciterons votre consentement avant toute nouvelle utilisation de vos données lorsque cela est nécessaire. Si votre consentement n’est pas nécessaire, nous vous en informerons toutefois préalablement à la mise en œuvre de ce nouveau traitement.

_**6. À qui sont communiquées vos données ?**_

Vos données seront communiquées aux seuls membres de notre personnel ayant besoin d’accéder à vos données (service informatique, service client, service communication), ainsi que nos sous-traitants techniques éventuels (prestataires informatiques, hébergeur du site internet). Ils n’auront accès qu’aux données nécessaires à l’exécution de leurs missions respectives. Par ailleurs, il est précisé que nos sous-traitants éventuels agissent uniquement sur nos instructions pour la seule exécution des missions que nous leur confions.

Dans le cas d’un contentieux, vos données pourront également être communiquées à des auxiliaires de justice, des officiers ministériels (commissaire de justice, greffier) ou des magistrats.

L’ensemble des destinataires s'engage à assurer la plus stricte confidentialité des données personnelles vous concernant en leur possession. Nous vous informons par ailleurs que vos données ne sont pas transférées à des tiers, filiales ou partenaires situés en dehors de l’Union européenne.

_**7. Pendant combien de temps sont conservées vos données personnelles ?**_

Vos données ne sont conservées que pour la durée strictement nécessaire à leur utilisation en fonction de l’objectif pour lequel elles sont collectées. Dans tous les cas, vos données personnelles ne seront pas conservées au-delà de cette durée.

- Les données relatives à la gestion et au suivi des relations avec nos clients et utilisateurs de notre Plateforme, ainsi qu’à la prospection commerciale (objectifs (i) à (iv)) :

Dans le cadre de la création du compte en ligne, les données personnelles collectées sont conservées jusqu’à la suppression du compte par l’utilisateur. A défaut de suppression volontaire de votre part, les données personnelles seront conservées pendant une durée maximale de deux (2) ans à compter de la création de ce compte. Avant l’expiration de ce délai, nous vous contacterons afin de vous laisser la possibilité d’exprimer votre souhait de maintenir votre compte actif. En l’absence de réponse positive et claire de votre part, vos données seront supprimées et votre compte désactivé.

En ce qui concerne l’abonnement à nos produits et services via la Plateforme, vos données personnelles ne seront pas conservées au-delà de la durée strictement nécessaire à la gestion de notre relation contractuelle avec vous, soit au plus tard la fin de l’abonnement qui nous lie, augmenté d’un délai de trois (3) ans à compter de la fin du contrat à des fins de prospection.

Au-delà de ce délai de trois ans, nous pourrons reprendre contact avec vous afin de savoir si vous souhaitez continuer de recevoir des sollicitations commerciales. En l’absence de réponse positive et explicite de la personne, les données seront supprimées.

En tout état de cause, à l’issue de ce délai, les données permettant d’établir la preuve d’un droit ou d’un contrat, devant être conservées dans notre base d’archivage intermédiaire au titre du respect d’une obligation légale, le seront pendant la durée prévue par la loi en vigueur, soit un délai de cinq (5) ans.

- Concernant les données relatives à la gestion comptables des abonnements (objectif (xi)) :

Dans le cadre de la gestion de nos relations contractuelles, certaines données sont collectées pour nous permettre de répondre à nos obligations comptables et notamment à la durée légale de conservation des documents justificatifs.

Ces données seront donc conservées pendant une période de dix (10) ans à compter de l’émission du document justificatif.

- Concernant les données relatives au bon fonctionnement et à l’optimisation de notre Plateforme (objectifs (v) à (vi)) :

Les données collectées aux fins d’amélioration de notre Plateforme et d’établissement de statistiques sont conservées pour une durée maximale d’un (1) an.

- Concernant les statistiques des quiz réalisés sur notre Plateforme (objectif (vii)) :

Les données collectées afin d’élaborer des statistiques d’utilisation et de pertinence des quiz réalisés sur notre Plateforme sont conservées pendant une durée de 3 ans, puis conservées sous forme anonymisée sans limitation de durée.

- Données utilisées dans le cadre de la gestion d’un éventuel contentieux quant à l’utilisation de notre Plateforme et des produits et services en découlant (objectif (viii)) :

Les données utilisées pour les besoins d’un litige ou d’une procédure judiciaire, qui pourrait survenir entre nous concernant l’utilisation de notre Plateforme ou encore des produits et/ou services en découlant auxquels vous auriez souscrits, seront conservées pendant toute la durée de la procédure en cours et jusqu’à ce qu’une décision de justice définitive ait été rendue.

- Concernant les demandes relatives à l’utilisation de la Plateforme ou l’exercice de vos droits (objectif (ix)) :

Lorsque vous nous contactez pour demander notre assistance sur l’utilisation de la Plateforme ou sur les produits et services en découlant, pour signaler un problème ou tout simplement exercer l’un de vos droits précisés ci-après, vos données sont conservées pour la durée nécessaire au traitement de votre demande.

Au-delà, nous conservons ces données dans notre base d’archivage intermédiaire pendant un délai complémentaire allant jusqu’à cinq (5) ans, correspondant à la durée au cours de laquelle une action judiciaire pourrait être introduite sur la base du traitement de votre demande.

_**8. Sécurité**_

Nous vous informons prendre toutes précautions utiles, ainsi que toutes mesures organisationnelles et techniques appropriées pour préserver la sécurité, l’intégrité et la confidentialité de vos données à caractère personnel et notamment, empêcher qu’elles soient déformées, endommagées ou que des tiers non autorisés y aient accès.

_**9. Où sont hébergées vos données ?**_

Nous vous informons que vos données sont conservées et stockées, pendant toute la durée de leur conservation sur les serveurs de la société Défimédoc, située en **France**.

_**10. Comment pouvez-vous accéder aux données personnelles que nous détenons sur vous ?**_

Vous disposez du droit d’accéder à vos données personnelles pour en obtenir la communication et le cas échéant en obtenir, la rectification ou l’effacement, en nous adressant un courrier à :

- adresse de courrier électronique : **contact@defimedoc.fr**

- adresse de courrier postal : 20 bis avenue Joffre, 92250 La Garenne-Colombes

Pour procéder à l’effacement de votre compte en ligne et aux données qui en découlent, il vous suffit de cliquer directement sur le bouton « suppression du compte » accessible via votre compte sur notre site internet ou notre application.

Nous vous rappelons également que toute personne peut, pour des motifs légitimes, demander la limitation au traitement des données la concernant ou s'opposer audit traitement.

Nous vous informons qu’en cas de rectification ou effacement de vos données à caractère personnel, ainsi que de limitation des traitements, réalisés à la suite d’une demande de votre part, nous notifierons lesdites modifications aux personnes auxquelles nous avons communiqués vos données, à moins qu’une telle communication s’avère impossible.

_**11. Portabilité de vos données personnelles**_

Vous disposez d’un droit à la portabilité des données personnelles que vous nous aurez fournies dans le cadre de l’utilisation de la Plateforme et/ou de la souscription à nos produits et services accessible par ce biais. Nous vous rappelons que ce droit ne porte pas sur les données collectées et traitées sur une autre base légale que le consentement ou l’exécution du contrat nous liant, et notamment les données collectées dans notre intérêt légitime.

Ce droit peut être exercé gratuitement, à tout moment, afin de récupérer et de conserver vos données personnelles.

Dans ce cadre, nous vous adresserons vos données personnelles, par tous moyens jugés utiles, dans un format ouvert standard couramment utilisé et lisible par machine, conformément à l’état de l’art.

_**12. Introduction d’une réclamation devant une autorité de contrôle**_

Vous êtes également informés que vous disposez du droit d’introduire une réclamation auprès de la Commission Nationale Informatique et Libertés si vous considérez que le traitement de vos données personnelles objet de cette Charte constitue une violation des textes applicables.

Ce recours pourra être exercé sans préjudice de tout autre recours devant une juridiction administrative ou juridictionnelle. En effet, vous disposez également d’un droit à un recours administratif ou juridictionnel effectif si vous considérez que le traitement de vos données personnelles objet de cette Charte constitue une violation des textes applicables.

_**13. Communication relative à une violation de données personnelles**_

Si nous constatons une faille de sécurité dans le traitement de vos données susceptibles d’entraîner un risque élevé pour vos droits et libertés, nous vous en informerons dans les meilleurs délais. Nous vous détaillerons à cette occasion la nature de la violation rencontrée et les mesures mises en place pour y mettre un terme.

_**14.  Modifications**_

Nous nous réservons le droit, à notre seule discrétion, de modifier à tout moment la présente charte, en totalité ou en partie. Ces modifications entreront en vigueur à compter de la publication de la nouvelle charte qui vous sera notifiée par tout moyen jugé utile.

_**15.  Entrée en vigueur**_

La présente charte est entrée en vigueur le 5 Décembre 2024.`
      }
    </Markdown>
  </PolicyLayout>

const Title = styled.h1`
  font-family: 'Brother 1816';
  font-style: normal;
  font-weight: 900;
  text-align: center;
`

export default PersonalData
