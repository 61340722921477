import { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors } from 'theme'
import { hasMin3Discounts, hasMin4Discounts } from 'utils/origin'

const DiscountBanner = ({
  title,
  description,
  isOnDrawer,
}) =>
  <Wrapper $isOnDrawer={ isOnDrawer }>
    <svg
      height={ hasMin4Discounts() ? '150%' : '125%' }
      preserveAspectRatio="none"
      viewBox={ hasMin3Discounts() ? '80 0 260 100' : '80 0 280 130' }
      width="100%"
    >
      <defs>
        <linearGradient
          gradientTransform="matrix(337.65, 0, 0, -82, 158223.26, 44958)"
          gradientUnits="userSpaceOnUse"
          id="gradient"
          x1="-468.93"
          x2="-467.52"
          y1="547.8"
          y2="547.74"
        >
          <stop
            offset="0"
            stopColor="#23bcba"
          />
          <stop
            offset="1"
            stopColor="#00e5a6"
          />
        </linearGradient>
      </defs>
      <g
        fill="url(#gradient)"
        fillRule="evenodd"
      >
        <path
          d="M3,0H334.5a3,3,0,0,1,3,3A3,3,0,0,1,337,4.63L314.24,39.94h0l23,37.49A3,3,0,0,1,334.64,82H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0Z"
          id="Rectangle"
        >
        </path>
      </g>
    </svg>
    <Title $isOnDrawer={ isOnDrawer }>
      {title}
    </Title>
    <Description>
      {description.map((text, key) =>
        <Fragment key={ key }>
          <span>{text}</span>
          <br />
        </Fragment>,
      )}
    </Description>
  </Wrapper>

DiscountBanner.propTypes = {
  description: PropTypes.arrayOf(PropTypes.string).isRequired,
  isOnDrawer: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
}

DiscountBanner.defaultProps = {
  isOnDrawer: false,
}

const Wrapper = styled.div`
  position: relative;
  ${({ $isOnDrawer }) => $isOnDrawer
    ? `
      right: 3rem;
      margin: -2.5rem 0 1.5rem 0;
    ` : `
      margin-top: -2rem;
      margin-left: -2rem;
    `
}
`

const Title = styled.span`
  position: absolute;
  top: 0.5rem;
  left: 1rem;
  height: 1.125rem;
  font-family: 'Brother 1816';
  font-weight: 800;
  color: ${colors.white};
  text-align: justify;
`

const Description = styled.span`
  position: absolute;
  top: 2rem;
  left: 1rem;
  width: 15rem;
  height: 1.125rem;
  font-family: 'Brother 1816';
  font-size: 0.8rem;
  font-weight: 600;
  color: ${colors.white};
`
export default DiscountBanner
