import { gql } from '@apollo/client'

export const sendContactForm = gql`
  mutation sendContactForm(
    $firstname: String!
    $lastname: String!
    $email: String!
    $address: String!
    $hasTwoReceivers: Boolean,
  ) {
    sendContactForm(
      firstname: $firstname,
      lastname: $lastname,
      email: $email,
      address: $address,
      hasTwoReceivers: $hasTwoReceivers,
    )
  }
`
