export const hasSpecialOffer = plans => {
  for (const planId in plans) {
    const plan = plans[planId]

    if (plan.metadata.discount !== undefined && plan.metadata.discount !== null) {
      return true
    }
  }

  return false
}

export const getAppliableDiscountLevel = (levels, licencesCount) => levels
  .filter(level => level.licencesCount <= licencesCount)
  .sort((levelA, levelB) => levelA.licencesCount > levelB.licencesCount ? -1 : 1)
  .shift()
