import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'components/atoms'
import { colors } from 'theme'

const NavItem = ({ isCurrent, href, children }) =>
  <Li>
    <Anchor
      $isCurrent={ isCurrent }
      href={ href }
    >
      {children}
    </Anchor>
  </Li>

NavItem.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  isCurrent: PropTypes.bool,
}
NavItem.defaultProps = {
  isCurrent: false,
}

const Li = styled.li`
  list-style-type: none;
`

const Anchor = styled(Button)`
  && {
    margin-right: 1.9rem;
    ${({ $isCurrent }) => !$isCurrent && 'opacity: 0.5'};
    font-family: 'Brother 1816';
    font-style: normal;
    font-weight: 500;
    color: ${colors.midnightBlue};
  }
`

export default NavItem
