import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Chart, PieController, ArcElement } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { colors } from 'theme'

const data = {
  type: 'pie',
  plugins: [ChartDataLabels],
  data: {
    labels: [
      'Médoc de bronze',
      'Médoc d\'argent',
      'Médoc d\'or',
      'Médoc de platine',
      'Médoc de diamand',
      'Médoc de titane',
      'Médoc d\'adamantium',
      'Médoc de saphire',
      'Médoc de ruby',
      'Médoc d\'émeraude',
      'Médoc d\'obsidienne',
      'Médoc de mithril',
    ],
    datasets: [
      {
        backgroundColor: [
          colors.bronze,
          colors.silver,
          colors.gold,
          colors.platinum,
          colors.diamond,
          colors.titanium,
          colors.quartz,
          colors.adamantium,
          colors.sapphire,
          colors.ruby,
          colors.emerald,
          colors.obsidian,
          colors.mithril,
        ],
        data: [],
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    responsive: true,
    layout: {
      padding: {
        left: 30,
        right: 30,
        bottom: 30,
        top: 30,
      },
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          family: 'Montserrat',
          weight: 'bold',
          color: colors.deepBlue,
        },
        formatter: (value, ctx) => {
          let sum = 0
          let dataArr = ctx.chart.data.datasets[0].data
          dataArr.map(data => {
            return sum += data
          })
          let percentage = (value * 100 / sum).toFixed(0) + '%'

          return percentage
        },
      },
    },
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
      },
    },
  },
}

const Badges = ({ members }) => {
  const canvas = useRef(null)
  const badgesArray = members.map(member => (
    member.monthlyStatistic.badge
  ))

  useEffect(() => {
    Chart.register(PieController, ArcElement)

    new Chart(canvas.current.getContext('2d'), {
      ...data,
      data: {
        ...data.data,
        datasets: [{
          ...data.data.datasets[0],
          data: [
            badgesArray.filter(x => x === 'BRONZE').length,
            badgesArray.filter(x => x === 'SILVER').length,
            badgesArray.filter(x => x === 'GOLDEN').length,
            badgesArray.filter(x => x === 'PLATINUM').length,
            badgesArray.filter(x => x === 'DIAMOND').length,
            badgesArray.filter(x => x === 'TITANIUM').length,
            badgesArray.filter(x => x === 'QUARTZ').length,
            badgesArray.filter(x => x === 'ADAMANTIUM').length,
            badgesArray.filter(x => x === 'SAPPHIRE').length,
            badgesArray.filter(x => x === 'RUBY').length,
            badgesArray.filter(x => x === 'EMERALD').length,
            badgesArray.filter(x => x === 'OBSIDIAN').length,
            badgesArray.filter(x => x === 'MITHRIL').length,
          ],
        }],
      },
    })
  })

  return (
    <>
      <Title>
        <h3>Répartition des badges</h3>
      </Title>
      <Wrapper>
        <LegendWrapper>
          <Legend>
            <Dot $color={ colors.bronze } />
            <LegendTextWrapper>
              <LegendName>
                Médoc de bronze
              </LegendName>
              <LegendLabel>
                De 0 à 1000 points
              </LegendLabel>
            </LegendTextWrapper>
          </Legend>
          <Legend>
            <Dot $color={ colors.silver } />
            <LegendTextWrapper>
              <LegendName>
                Médoc d&apos;argent
              </LegendName>
              <LegendLabel>
                De 1001 à 3000 points
              </LegendLabel>
            </LegendTextWrapper>
          </Legend>
          <Legend>
            <Dot $color={ colors.gold } />
            <LegendTextWrapper>
              <LegendName>
                Médoc d&apos;or
              </LegendName>
              <LegendLabel>
                De 3001 à 8000 points
              </LegendLabel>
            </LegendTextWrapper>
          </Legend>
          <Legend>
            <Dot $color={ colors.platinum } />
            <LegendTextWrapper>
              <LegendName>
                Médoc de platine
              </LegendName>
              <LegendLabel>
                De 8001 à 20 000 points
              </LegendLabel>
            </LegendTextWrapper>
          </Legend>
          <Legend>
            <Dot $color={ colors.diamond } />
            <LegendTextWrapper>
              <LegendName>
                Médoc de diamant
              </LegendName>
              <LegendLabel>
                De 20 001 points à 30 000 points
              </LegendLabel>
            </LegendTextWrapper>
          </Legend>
          <Legend>
            <Dot $color={ colors.titanium } />
            <LegendTextWrapper>
              <LegendName>
                Médoc de titane
              </LegendName>
              <LegendLabel>
                De 30 001 points à 40 000 points
              </LegendLabel>
            </LegendTextWrapper>
          </Legend>
          <Legend>
            <Dot $color={ colors.quartz } />
            <LegendTextWrapper>
              <LegendName>
                Médoc de quartz
              </LegendName>
              <LegendLabel>
                De 40 001 points à 50 000 points
              </LegendLabel>
            </LegendTextWrapper>
          </Legend>
          <Legend>
            <Dot $color={ colors.adamantium } />
            <LegendTextWrapper>
              <LegendName>
                Médoc d&apos;adamantium
              </LegendName>
              <LegendLabel>
                De 50 001 points à 60 000 points
              </LegendLabel>
            </LegendTextWrapper>
          </Legend>
          <Legend>
            <Dot $color={ colors.sapphire } />
            <LegendTextWrapper>
              <LegendName>
                Médoc de saphir
              </LegendName>
              <LegendLabel>
                De 60 001 points à 70 000 points
              </LegendLabel>
            </LegendTextWrapper>
          </Legend>
          <Legend>
            <Dot $color={ colors.ruby } />
            <LegendTextWrapper>
              <LegendName>
                Médoc de rubis
              </LegendName>
              <LegendLabel>
                De 70 001 points à 80 000 points
              </LegendLabel>
            </LegendTextWrapper>
          </Legend>
          <Legend>
            <Dot $color={ colors.emerald } />
            <LegendTextWrapper>
              <LegendName>
                Médoc d&apos;émeraude
              </LegendName>
              <LegendLabel>
                De 80 001 points à 90 000 points
              </LegendLabel>
            </LegendTextWrapper>
          </Legend>
          <Legend>
            <Dot $color={ colors.obsidian } />
            <LegendTextWrapper>
              <LegendName>
                Médoc d&apos;obsidienne
              </LegendName>
              <LegendLabel>
                De 90 001 points à 100 000 points
              </LegendLabel>
            </LegendTextWrapper>
          </Legend>
          <Legend>
            <Dot $color={ colors.mithril } />
            <LegendTextWrapper>
              <LegendName>
                Médoc de mithril
              </LegendName>
              <LegendLabel>
                Plus de 100 001 points
              </LegendLabel>
            </LegendTextWrapper>
          </Legend>
        </LegendWrapper>
        <ChartContainer>
          <canvas height="400"
            id="badges"
            ref={ canvas }
          >
          </canvas>
        </ChartContainer>
      </Wrapper>
    </>
  )
}

Badges.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({
    monthlyStatistic: PropTypes.shape({
      badge: PropTypes.string,
    }),
  })).isRequired,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const ChartContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  width: 65%;
`

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h3 {
    font-family: Montserrat;
    color: ${colors.deepBlue};
  }
`
const LegendTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const LegendWrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  width: auto;
  max-width: 35%;
`

const Legend = styled.div`
  display: flex;
  flex-direction: colum;
  align-items: center;
  margin-bottom: 1rem;
`

const Dot = styled.span`
  display: inline-block;
  width: 0.875rem;
  height: 0.875rem;
  background-color: ${({ $color }) => $color};
  border-radius: 100%;
`

const LegendLabel = styled.span`
  display: flex;
  flex-direction: row;
  margin-right: 1.875rem;
  margin-left: 0.5rem;
  font-family: 'Brother 1816';
  font-size: 0.75rem;
  color: ${colors.deepBlue};
  opacity: 0.5;
`

const LegendName = styled.span`
  margin-right: 1.875rem;
  margin-bottom: 0.25rem;
  margin-left: 0.5rem;
  font-family: 'Brother 1816';
  font-size: 0.875rem;
  color: ${colors.deepBlue};
`

export default Badges
