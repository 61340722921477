import {
  giphar,
  fspf,
  giropharm2,
  uspo,
  teams,
  labofficine,
  ubipharm,
  vingtQuatreSept,
  clubDuComptoir,
  totum6,
  pharmUppEssential,
  pharmUppUltra,
  soloDefimedoc,
  pharmaConsulting,
  capUnipharm,
  urpsPharmaciens,
  dephie,
  ceido,
  act,
  sponsoring,
  oxygenpharma,
  pharma2m,
  settings37dms,
  teampharma1,
} from 'constants/discountModals'
import {
  defaultSubscriptionDetails,
  threeMonthsSubscriptionDetails,
  twoMonthsSubscriptionDetails,
} from 'constants/subscription'

export const isGiphar = () =>
  window.location.origin === process.env.REACT_APP_GIPHAR_URL

export const isFspf = () =>
  window.location.origin === process.env.REACT_APP_FSPF_URL

export const isGiropharm2 = () =>
  window.location.origin === process.env.REACT_APP_GIROPHARM2_URL

export const isUspo = () =>
  window.location.origin === process.env.REACT_APP_USPO_URL

const isTeams = () =>
  window.location.origin === process.env.REACT_APP_TEAMS_URL

export const isLabofficine = () =>
  window.location.origin === process.env.REACT_APP_LABOFFICINE_URL

export const isUbipharm = () =>
  window.location.origin === process.env.REACT_APP_UBIPHARM_URL

const isClubDuComptoir = () =>
  window.location.origin === process.env.REACT_APP_CLUB_DU_COMPTOIR_URL

export const isVingtQuatreSept = () =>
  window.location.origin === process.env.REACT_APP_VINGT_QUATRE_SEPT_URL

export const isTotum6 = () =>
  window.location.origin === process.env.REACT_APP_TOTUM6_URL

export const isPharmUppEssential = () =>
  window.location.origin === process.env.REACT_APP_PHARM_UPP_ESSENTIAL_URL

export const isPharmUppUltra = () =>
  window.location.origin === process.env.REACT_APP_PHARM_UPP_ULTRA_URL

export const isSoloDefimedoc = () =>
  window.location.origin === process.env.REACT_APP_SOLO_DEFIMEDOC_URL

export const isPharmaConsulting = () =>
  window.location.origin === process.env.REACT_APP_PHARMA_CONSULTING_URL

export const isCapUnipharm = () =>
  window.location.origin === process.env.REACT_APP_CAP_UNIPHARM_URL

export const isUrpsPharmaciens = () =>
  window.location.origin === process.env.REACT_APP_URPS_PHARMACIENS_URL

export const isDephie = () =>
  window.location.origin === process.env.REACT_APP_DEPHIE_URL

export const isCeido = () =>
  window.location.origin === process.env.REACT_APP_CEIDO_URL

export const isAct = () =>
  window.location.origin === process.env.REACT_APP_ACT_URL

export const isSponsoring = () =>
  window.location.origin === process.env.REACT_APP_SPONSORING_URL

export const isOxygenpharma = () =>
  window.location.origin === process.env.REACT_APP_OXYGENPHARMA_URL

export const isPharma2m = () =>
  window.location.origin === process.env.REACT_APP_PHARMA2M_URL

export const is37DMS = () => window.location.origin === process.env.REACT_APP_37DMS_URL

export const isTeampharma1 = () => window.location.origin === process.env.REACT_APP_TEAMPHARMA1_URL

export const hasMin3Discounts = () =>
  isFspf() || isUspo() || isLabofficine() || isGiropharm2() || isTeams() || isVingtQuatreSept() || isTotum6() || isPharmUppEssential() || isPharmaConsulting() || isCapUnipharm() || isUrpsPharmaciens() || isDephie() || isCeido() || isSponsoring() || is37DMS() || isTeampharma1()

export const hasMin4Discounts = () =>
  isAct() || isGiphar() || isOxygenpharma() || isPharma2m()

export const hasModal = () =>
  isGiphar()
  || isFspf()
  || isGiropharm2()
  || isUspo()
  || isTeams()
  || isLabofficine()
  || isUbipharm()
  || isVingtQuatreSept()
  || isClubDuComptoir()
  || isTotum6()
  || isPharmUppEssential()
  || isPharmUppUltra()
  || isSoloDefimedoc()
  || isPharmaConsulting()
  || isCapUnipharm()
  || isUrpsPharmaciens()
  || isDephie()
  || isCeido()
  || isAct()
  || isSponsoring()
  || isOxygenpharma()
  || isPharma2m()
  || is37DMS()
  || isTeampharma1()

export const isSubdomain = () => hasModal()

const isSubdomainWithTwoMonthsOfTrialPeriod = () => isTeams() || isLabofficine() || isGiropharm2() || isGiphar() || isFspf() || isUspo() || isClubDuComptoir() || isPharmUppEssential() || isPharmaConsulting() || isCapUnipharm() || isUrpsPharmaciens() || isDephie() || isCeido() || isAct() || isSponsoring() || isOxygenpharma() || isPharma2m() || is37DMS() || isTeampharma1()

const isSubdomainWithThreeMonthsOfTrialPeriod = () => isTotum6()

export const getSubscriptionDetails = (isTeamMensualSubscription = false) => {
  if (isSubdomainWithTwoMonthsOfTrialPeriod() && isTeamMensualSubscription) {
    return twoMonthsSubscriptionDetails
  }

  if (isSubdomainWithThreeMonthsOfTrialPeriod() && isTeamMensualSubscription) {
    return threeMonthsSubscriptionDetails
  }

  return defaultSubscriptionDetails
}

export const getDiscountModalData = () => {
  if (isGiphar()) {
    return giphar
  }

  if (isFspf()) {
    return fspf
  }

  if (isGiropharm2()) {
    return giropharm2
  }

  if (isUspo()) {
    return uspo
  }

  if (isTeams()) {
    return teams
  }

  if (isLabofficine()) {
    return labofficine
  }

  if (isUbipharm()) {
    return ubipharm
  }

  if (isVingtQuatreSept()) {
    return vingtQuatreSept
  }

  if (isClubDuComptoir()) {
    return clubDuComptoir
  }

  if (isTotum6()) {
    return totum6
  }

  if (isPharmUppEssential()) {
    return pharmUppEssential
  }

  if (isPharmUppUltra()) {
    return pharmUppUltra
  }

  if (isSoloDefimedoc()) {
    return soloDefimedoc
  }

  if (isPharmaConsulting()) {
    return pharmaConsulting
  }

  if (isCapUnipharm()) {
    return capUnipharm
  }

  if (isUrpsPharmaciens()) {
    return urpsPharmaciens
  }

  if (isDephie()) {
    return dephie
  }

  if (isCeido()) {
    return ceido
  }

  if (isAct()) {
    return act
  }

  if (isSponsoring()) {
    return sponsoring
  }

  if (isOxygenpharma()) {
    return oxygenpharma
  }

  if (isPharma2m()) {
    return pharma2m
  }

  if (is37DMS()) {
    return settings37dms
  }

  if (isTeampharma1()) {
    return teampharma1
  }

  return null
}
