import React from 'react'
import PropTypes from 'prop-types'
import { rgba } from 'polished'
import styled from 'styled-components'
import { Icon } from 'components/atoms'
import { colors } from 'theme'

const washedWhite = rgba(colors.white, 0.85)

const LicencesThreshold = ({ threshold }) =>
  <Wrapper>
    <IconsWrapper>
      {[...Array(threshold)].map((_, index) =>
        <StyledIcon
          $index={ index }
          fill={ washedWhite }
          key={ index }
          name="user"
          size="1em"
          stroke={ threshold > 1
            ? colors.blueLagoon
            : null }
          strokeWidth={ 5 }
        />,
      )}
    </IconsWrapper>
    <LicencesCount>
      {threshold > 1
        ? 'À partir de 2 licences, dont la vôtre'
        : 'Une licence'
      }
    </LicencesCount>
  </Wrapper>

LicencesThreshold.propTypes = {
  threshold: PropTypes.number.isRequired,
}

const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
`

const IconsWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  margin-right: 0.6875rem;
`

const StyledIcon = styled(Icon)`
  && {
    ${({ $index }) => $index !== 0 ? 'margin-left: -0.4rem;' : ''}
  }
`

const LicencesCount = styled.span`
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  color: ${washedWhite};
`

export default LicencesThreshold
