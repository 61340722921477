import Rollbar from 'rollbar'

const rollbar = new Rollbar({
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
})

export const logError = (subject, message, error) => {
  const { networkError } = error
  let errorMessage = ''
  if (networkError !== undefined) {
    errorMessage = networkError?.result?.errors[0]?.message
  }
  rollbar.error(`${subject} - ${message} : ${errorMessage}`)
  if (process.env.NODE_ENV !== 'production') {
    console.error(`${subject} - ${message} : `, errorMessage)
  }
}

export default rollbar
