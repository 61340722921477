import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { logError } from 'logService'
import { toast } from 'react-toastify'
import ContactForm from './ContactForm'
import { sendContactForm as SEND_CONTACT_FORM } from 'graphql/mutations/contactForm'
import { USER } from 'graphql/queries/user'
import { isEmail } from 'utils/regex'
import { isUbipharm } from 'utils/origin'
import { DEFAULT_COUNTRY } from 'constants/countries'

const Container = () => {
  const [sendContactForm] = useMutation(SEND_CONTACT_FORM)
  const [form, setForm] = useState({
    lastname: '',
    firstname: '',
    email: '',
    address: '',
    hasTwoReceivers: isUbipharm() ? true : false,
  })
  const [formDataLoaded, setFormDataLoaded] = useState(false)
  const [errors, setErrors] = useState({})

  useQuery(USER, {
    onCompleted: data => {
      if (formDataLoaded || data.me === null) {
        return
      }

      const { firstname, lastname, email } = data.me

      let {
        address,
        address2,
        city,
        zipCode,
        country,
      } = data.me.address

      address = address !== null ? address : ''
      address2 = address2 !== null ? address2 : ''
      city = city !== null ? city : ''
      zipCode = zipCode !== null ? zipCode : ''
      country = country !== null ? country : DEFAULT_COUNTRY

      let fetchedData = {
        firstname,
        lastname,
        email,
        address: `${address} ${address2} ${city} ${zipCode} ${country}`,
      }

      setForm({
        ...form,
        ...fetchedData,
      })

      setFormDataLoaded(true)
    },
  })

  const handleChange = (value, name) => {
    let updatedErrors = { ...errors }

    updateFormValue(value, name)

    setErrors({
      ...updatedErrors,
      [name]: null,
    })
  }

  const updateFormValue = (value, name) => {
    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const {
      lastname,
      firstname,
      email,
      address,
      hasTwoReceivers,
    } = form
    const errors = {}

    if (firstname.length === 0) {
      errors.firstname = 'Champ obligatoire'
    }
    if (lastname.length === 0) {
      errors.lastname = 'Champ obligatoire'
    }
    if (!isEmail.test(email)) {
      errors.email = 'Champ incorrect'
    }
    if (address.length === 0) {
      errors.address = 'Champ obligatoire'
    }

    setErrors(errors)
    const hasError = Object.values(errors).some(error => typeof error === 'string')

    if (!hasError) {
      try {
        await sendContactForm({
          variables: {
            firstname,
            lastname,
            email,
            address,
            hasTwoReceivers,
          },
        })

        toast.success('Formulaire envoyé avec succès', {
          position: 'top-center',
        })
      } catch (e) {
        toast.error('Une erreur est survenue', {
          position: 'top-center',
        })
        logError('GRAPHQL', 'sendContactForm', e)
      }
    }
  }

  return (
    <ContactForm
      errors={ errors }
      form={ form }
      onChange={ handleChange }
      onSubmit={ handleSubmit }
    />
  )
}

export default Container
