import React, { CSSProperties, FC } from 'react'
import styled from 'styled-components'
import { colors } from 'theme'

type Props = {
  isOpen?: boolean;
  style?: CSSProperties;
}

const DropdownIndicator: FC<Props> = ({ isOpen, style }) => {
  return (
    <Wrapper style={ style }>
      <Chevron
        $isOpen={ isOpen }
        style={ style }
      />
    </Wrapper>
  )
}
type ChevronProps = {
  $isOpen?: boolean
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
`

const Chevron = styled.div<ChevronProps>`
  border-right: 6px solid transparent;
  border-bottom: 6px solid ${colors.mediumGray};
  border-left: 6px solid transparent;
  transition: transform 100ms ease-in-out;
  transform: ${({ $isOpen }) => `rotate(${$isOpen ? '180' : '0'}deg)`};
`

export default DropdownIndicator
