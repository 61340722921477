import { gql } from '@apollo/client'

export const login = gql`
  mutation login {
    authentication {
      authToken
      requireEmailValidation
    }
  }
`

export const askResetPassword = gql`
  mutation askResetPassword($email: String!) {
    askResetPassword(email: $email)
  }
`

export const resetPassword = gql`
    mutation resetPassword($token: ID!, $password: String!) {
        resetPassword(token: $token, password: $password)
    }
`
