import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { logError } from 'logService'
import { SUBSCRIPTION } from 'graphql/queries/user'
import CurrentSubscription from './CurrentSubscription'
import { ModalContext } from 'context/Modal'
import { CANCEL_SUBSCRIPTION } from 'graphql/mutations/user'
import { useQuery, useMutation } from '@apollo/client'

const Container = ({
  isOwner,
}) => {
  const userQuery = useQuery(SUBSCRIPTION)
  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION)

  const { actions } = useContext(ModalContext)
  const { closeModal, setModal } = actions
  const [justCanceled, setJustCanceled] = useState(false)

  if (userQuery.loading || !userQuery.data?.me) {
    return null
  }

  const { plan, quantity, status, platform } = userQuery.data.me.subscription
  const { title } = plan

  const onGoingSubscription = status !== 'canceled' && !justCanceled

  const handleCancelSubscriptionClick = async () => {
    if (!onGoingSubscription) {
      return
    }

    setModal({
      title: 'Annuler mon abonnement',
      details: `Attention vous êtes sur le point d'annuler votre abonnement.
                - Si vous êtes en phase d'essai, vous n'aurez plus accès aux Quiz Défimédoc après votre période d'essai.
                - Si vous êtes en cours d'abonnement, vous conservez l'accès aux Quiz jusqu'à la fin de votre période d'abonnement.`,
      cancel: {
        text: 'Annuler',
        handleClick: closeModal,
      },
      validate: {
        text: 'Me désabonner',
        handleClick: handleCancelSubscription,
      },
    })
  }

  const handleCancelSubscription = async () => {
    try {
      const { data } = await cancelSubscription()
      if (data?.cancelSubscription?.id !== undefined) {
        setJustCanceled(true)
      }
    } catch (e) {
      toast.error('Oups, une erreur est survenue', {
        position: 'top-center',
      })
      logError('GRAPHQL', 'cancelSubscription', e)
    }
    closeModal()
  }

  return (
    <CurrentSubscription
      automaticRenewal={ onGoingSubscription }
      isOwner={ isOwner }
      licenceCount={ quantity }
      platform={ platform }
      subscriptionName={ title }
      onChange={ handleCancelSubscriptionClick }
    />
  )
}

Container.propTypes = {
  isOwner: PropTypes.bool.isRequired,
}

export default Container
