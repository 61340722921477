import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Chart, LineController, PointElement, LineElement, Filler } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { colors } from 'theme'

const data = {
  type: 'line',
  plugins: [ChartDataLabels],
  data: {
    labels: [],
    datasets: [
      {
        backgroundColor: colors.lightGreen,
        data: [],
        pointRadius: 13,
        pointBorderColor: 'white',
        pointBorderWidth: 8,
        hoverRadius: 13,
        hoverBorderWidth: 8,
        fill: 'origin',
      },
    ],
  },
  options: {
    layout: {
      padding: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 40,
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          family: 'Montserrat',
          weight: 'bold',
          color: colors.deepBlue,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          padding: 15,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          padding: 15,
        },
      },
    },
  },
}

const TotalScore = ({ scores, formatedMonthYears }) => {
  const canvas = useRef(null)
  useEffect(() => {
    Chart.register(LineController, PointElement, LineElement, Filler)

    new Chart(canvas.current.getContext('2d'), {
      ...data,
      data: {
        ...data.data,
        labels: formatedMonthYears,
        datasets: [{
          ...data.data.datasets[0],
          data: scores.map(score => score.sumCalculatedScore),
        }],
      },
    })
  })

  return (
    <Wrapper>
      <Title>
        <h3>Score cumulé de l&apos;équipe</h3>
      </Title>
      <canvas height="400"
        id="totalScore"
        ref={ canvas }
      >
      </canvas>
    </Wrapper>
  )
}

TotalScore.propTypes = {
  formatedMonthYears: PropTypes.arrayOf(PropTypes.string).isRequired,
  scores: PropTypes.arrayOf(PropTypes.shape({
    sumCalculatedScore: PropTypes.number.isRequired,
  })).isRequired,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h3 {
    font-family: Montserrat;
    color: ${colors.deepBlue};
  }
`

export default TotalScore
