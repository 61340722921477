import CookieBanner from 'components/CookieBanner'
import { createContext, FC, PropsWithChildren, useState } from 'react'

type CookieBannerContextType = {
  isVisible: boolean;
  show: () => void;
  onAccept: () => void;
};

export const CookieBannerContext = createContext<CookieBannerContextType>({
  isVisible: false,
  show: () => {},
  onAccept: () => {},
})

export const CookieBannerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false)

  const show = () => setIsVisible(true)
  const onAccept = () => setIsVisible(false)

  return (
    <CookieBannerContext.Provider value={ { isVisible, show, onAccept } }>
      {children}
      <CookieBanner />
    </CookieBannerContext.Provider>
  )
}

export default CookieBannerProvider
