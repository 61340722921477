import React from 'react'
import styled from 'styled-components'
import FigureItem from 'components/FigureItem'

const infoList = [
  {
    image: require('assets/images/landing/infoItemBottom1.png'),
    alt: 'communication',
    title: 'Assistance 5j/7',
    description: 'Un N° dédié pour toutes vos questions',
  },
  {
    image: require('assets/images/landing/infoItemBottom2.png'),
    alt: 'paiement',
    title: 'Paiement sécurisé',
    description: 'La sécurité est notre priorité',
  },
  {
    image: require('assets/images/landing/infoItemBottom3.png'),
    alt: 'protection',
    title: 'Protection du compte',
    description: 'et de vos données personnelles',
  },
]

const ReassuranceItems = () =>
  <InfoList>
    { infoList.map((info, i) =>
      <FigureItem
        key={ i }
        { ...info }
      />,
    )}
  </InfoList>

const InfoList = styled.ul`
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  margin-bottom: 3rem;
`

export default ReassuranceItems
