import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'
import { Icon } from 'components/atoms'
import { colors } from 'theme'
import { phone } from 'constants/contact'

const Contact = ({
  lowAlpha,
}) =>
  <Wrapper
    $lowAlpha={ lowAlpha }
    id="contact"
  >
    <Title>
      Besoin
      <TitleColored>
        {' d’informations '}
      </TitleColored>
      supplémentaires ?
    </Title>
    <ContactsRow>
      <Phone>
        <Anchor href={ `tel:${phone}` }>
          <StyledIcon
            fill={ colors.lightGreen }
            name="phone"
            size="1.2em"
          />
          {phone}
        </Anchor>
      </Phone>
      <Email>
        <Anchor href="mailto:contact@defimedoc.fr">
          <StyledIcon
            fill={ colors.lightGreen }
            name="mail"
            size="1.2em"
          />
          contact@defimedoc.fr
        </Anchor>
      </Email>
    </ContactsRow>
  </Wrapper>

Contact.propTypes = {
  lowAlpha: PropTypes.bool,
}

Contact.defaultProps = {
  lowAlpha: false,
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.9rem 0;
  ${({ $lowAlpha }) => $lowAlpha
    ? `background-color: ${rgba(colors.darkBlue, 0.5)};`
    : `background-color: ${colors.darkBlue};`}
`

const Title = styled.h2`
  margin-bottom: 2rem;
  font-family: 'Brother 1816';
  font-size: 2.25rem;
  font-style: normal;
  font-weight: bold;
  line-height: 2.5rem;
  color: ${colors.white};
  text-align: center;
  white-space: pre-line;
`

const TitleColored = styled.span`
  color: ${colors.lightGreen};
`

const rowStyle = `
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
`

const ContactsRow = styled.div`
  ${rowStyle};
  flex-wrap: wrap;
  justify-content: center;

  @media (width <= 820px) {
    flex-direction: column;
  }
`

const StyledIcon = styled(Icon)`
  margin-right: 1rem;
`

const Phone = styled.div`
  ${rowStyle};
`

const Email = styled.div`
  ${rowStyle};
  margin-left: 1rem;
`

const Anchor = styled.a`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  font-family: Montserrat;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.25rem;
  color: ${colors.white};
`

export default Contact
