import { gql } from '@apollo/client'

export const USER = gql`
  query Me {
    me {
      id
      avatar
      firstname
      lastname
      username
      email
      dpcAccess {
        startDate
        endDate
        isCompleted
      }
      address {
        address
        address2
        city
        zipCode
        country
      }
      pharmacy {
        id
        name
        address
        zipCode
        city
        country
        organization
      }
      monthlyStatistic{
        badge
        lastQuizDate
        totalQuizDone
        totalQuizUnlocked
      }
    }
  }
`

export const PHARMACY = gql`
  query MyPharmacy {
    me {
      id
      pharmacy {
        id
        name
        address
        zipCode
        city
        country
        organization
      }
    }
  }
`

export const SUBSCRIPTION = gql`
  query MySubscription {
    me {
      id
      subscription {
        id
        user {
          id
        }
        platform
        teamMembers {
          id
          firstname
          lastname
          job
          monthlyStatistic{
            badge
            lastQuizDate
            totalQuizDone
            totalQuizUnlocked
          }
        }
        plan {
          id
          amount
          title
          interval
          isTeam
          metadata {
            taxType
            threshold
            displayPrice
            discount {
              title
              description
              levels {
                label
                licencesCount
                percentOff
              }
            }
          }
        }
        quantity
        status
        startDate
        trialPeriodDays
      }
    }
  }
`

export const LICENCES_STATUS = gql`
  query MyLicencesStatus {
    me {
      id
      email
      subscription {
        id
        quantity
        teamMembers {
          id
          firstname
          lastname
          email
        }
        invitations {
          firstname
          lastname
          email
        }
      }
    }
  }
`

export const PAYMENT_METHOD = gql`
  query MyPaymentMethod {
    me {
      id
      paymentMethod {
        name
        type
        email
        country
        last4
      }
    }
  }
`

export const INVOICES = gql`
  query MyInvoices {
    me {
      id
      invoices {
        createdAt
        amount
        pdfUrl
      }
    }
  }
`

export const BILLING_ADDRESS = gql`
  query MyBillingAddress {
    me {
      id
      pharmacy {
        name
      }
      billingAddress {
        address
        address2
        zipCode
        city
        country
      }
    }
  }
`
