import React, { FC } from 'react'
import styled from 'styled-components'
import { Button } from 'components/atoms'
import { colors } from 'theme'
import { PaymentMethodType } from 'types/graphql'
import { useMemo } from 'react'

type PaymentMethodPropType = {
  name?: null|string;
  type: PaymentMethodType;
  email?: null|string;
  country?: null|string;
  last4?: null|string;
}

type Props = {
  paymentMethod?: null|PaymentMethodPropType;
  onToggleEdit: () => void;
}

const PaymentMethod: FC<Props> = ({
  paymentMethod,
  onToggleEdit,
}) => {

  const paymentMethodNumber = useMemo(() => {
    if (paymentMethod == null) {
      return null
    }

    if (paymentMethod.type === PaymentMethodType.SEPA) {
      return `${paymentMethod.country}** ***** **** **** **** **** ${paymentMethod.last4 }`
    }

    return `**** **** **** ${paymentMethod.last4}`
  }, [paymentMethod])

  return (
    <Wrapper>
      <Top>
        <Title>
          MODE DE PAIEMENT
        </Title>
        <Button
          variant="tertiary"
          onClick={ onToggleEdit }
        >
          {
            (paymentMethod == null)
            ? 'Ajouter un mode de paiement'
            : 'Modifier mon mode de paiement'
          }
        </Button>
      </Top>
      {
        (paymentMethod != null)
        ?
          <>
            <PaymentType>
              { paymentMethod.type === PaymentMethodType.SEPA ? 'Prélèvement SEPA' : 'Carte bancaire'}
            </PaymentType>
            <PaymentMethodName>
              {paymentMethod.name}
            </PaymentMethodName>
            <PaymentNumber>
              {paymentMethodNumber}
            </PaymentNumber>
          </>
        :
          <EmptyText>
            Aucun mode de paiement enregistré
          </EmptyText>
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Top = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const PaymentType = styled.div`
  margin-bottom: 0.5rem;
  font-family: 'Brother 1816';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: normal;
  color: ${colors.midnightBlue};
`

const Title = styled.span`
  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  color: ${colors.darkGray};
  opacity: 0.5;
`

const PaymentMethodName = styled.span`
  margin-bottom: 0.5rem;
  font-family: 'Brother 1816';
  font-size: 1rem;
  font-style: normal;
  font-weight: bold;
  color: ${colors.midnightBlue};
`

const PaymentNumber = styled.span`
  font-family: 'Brother 1816';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: normal;
  color: ${colors.midnightBlue};
  opacity: 0.5;
`

const EmptyText = styled.span`
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: normal;
  color: ${colors.midnightBlue};
  opacity: 0.5;
`

export default PaymentMethod
