import styled from 'styled-components'
import { colors } from 'theme'

const Separator = styled.hr`
  width: 4rem;
  height: 0.3rem;
  margin: 1.5625rem 0;
  background-image: linear-gradient(to right, ${colors.seaGreen}, ${colors.lightGreen});
  border: none;
  border-radius: 2px;
`

export default Separator
