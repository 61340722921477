import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Icon } from 'components/atoms'
import { colors } from 'theme'

const RecapItem = ({ children }) =>
  <Li>
    <Icon
      fill={ colors.lightGreen }
      name="heavyCheck"
      size="0.7rem"
    />
    <Text>
      {children}
    </Text>
  </Li>

RecapItem.propTypes = {
  children: PropTypes.node.isRequired,
}

const Li = styled.ul`
  display: flex;
  flex-shrink: 0;
  margin: 0 -2.5rem 1rem;
  list-style-type: none;
`

const Text = styled.span`
  margin-left: 0.75rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  color: ${colors.white};
`

export default RecapItem
