import React from 'react'
import styled from 'styled-components'
import { Icon } from 'components/atoms'
import { colors } from 'theme'

const Header = () =>
  <Wrapper>
    <Icon
      fill={ colors.lightGreen }
      href="/"
      name="logo"
      size="3.75rem"
    />
    <Title href="/">
      défimédoc
    </Title>
  </Wrapper>

const Wrapper = styled.header`
  display: flex;
  flex-shrink: 0;
  align-items: center;
`

const Title = styled.a`
  margin: 0 0 0 0.7rem;
  font-family: 'Brother 1816';
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 800;
  color: ${colors.midnightBlue};
  cursor: pointer;
  user-select: none;
`

export default Header
