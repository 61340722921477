/**
 * Extract the payload from a JWT.
 * @param {string} token - The JWT to decode.
 * @param {string} [field] - The field to extract from the payload.
 * @returns {Object|string|null} - The payload, or the field if specified, or null if an error occured.
 */
function jwtPayloadExtractor(token, field = '') {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))

    const payload = JSON.parse(jsonPayload)

    if (field && payload[field]) {
      return payload[field]
    }

    if (field && !payload[field]) {
      console.error(`Error: cannot extract ${field} from JWT`)
    }

    return payload
  } catch (e) {
    console.error('Error: cannot extract payload from JWT', e)

    return null
  }
}

export default jwtPayloadExtractor
